import React, { useState } from "react";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";
import courierImage from "../assets/HomeImage/courierimg.jpg";
import { motion } from "framer-motion";

const CreateAccount = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordConditions, setPasswordConditions] = useState({
    minLength: false,
    upperLowercase: false,
    number: false,
    specialChar: false,
  });

  const validate = () => {
    const newErrors = {};
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^[0-9]{10}$/;

    // Full Name Validation
    if (!formData.fullName) {
      newErrors.fullName = "Full Name is required.";
    } else if (!nameRegex.test(formData.fullName)) {
      newErrors.fullName = "Name can only contain alphabets.";
    }

    // Email Validation
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    // Phone Number Validation
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required.";
    } else if (!phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number must be 10 digits.";
    }

    // Password Validation
    if (!formData.password) {
      newErrors.password = "Password is required.";
    } else {
      if (!passwordConditions.minLength)
        newErrors.password = "Password must be at least 6 characters.";
      else if (!passwordConditions.upperLowercase)
        newErrors.password =
          "Password must contain upper and lowercase letters.";
      else if (!passwordConditions.number)
        newErrors.password = "Password must include at least one number.";
      else if (!passwordConditions.specialChar)
        newErrors.password = "Password must contain a special character.";
    }

    return newErrors;
  };

  const handlePasswordChange = (value) => {
    setFormData({ ...formData, password: value });
    setPasswordConditions({
      minLength: value.length >= 6,
      upperLowercase: /[a-z]/.test(value) && /[A-Z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      alert("Account created successfully!");
      console.log("Form Data: ", formData);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-white p-6 md:p-14 w-full">
      <div className="bg-white flex flex-col md:flex-row rounded-lg shadow-lg w-full">
        {/* Left Side: Image with Text */}
        <div className="relative flex-1 basis-1/2 flex items-center justify-center bg-gray-200 rounded-t-lg md:rounded-l-lg md:rounded-t-none overflow-hidden">
          {/* Image */}
          <img
            src={courierImage}
            alt="Placeholder"
            className="absolute inset-0 w-full h-full object-cover"
          />
          {/* Text Above Image */}
          <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-white p-6">
            <h1 className="text-4xl font-bold">Welcome to Delhivery!</h1>
            <p className="mt-4 text-center text-lg">
              Empowering Your Logistics with Smart Solutions
            </p>
          </div>
        </div>

        {/* Right Side: Form */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-full md:w-1/2 p-1"
        >
          <div className="flex-1 basis-1/2 p-8">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Create your account
            </h2>
            <form onSubmit={handleSubmit} className="space-y-5">
              {/* Full Name */}
              <div>
                <input
                  type="text"
                  placeholder="Enter full name"
                  value={formData.fullName}
                  onChange={(e) =>
                    setFormData({ ...formData, fullName: e.target.value })
                  }
                  className="w-full border p-2 rounded"
                />
                {errors.fullName && (
                  <p className="text-red-500 text-sm">{errors.fullName}</p>
                )}
              </div>

              {/* Email */}
              <div>
                <input
                  type="email"
                  placeholder="mail@example.com"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className="w-full border p-2 rounded"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              {/* Phone Number */}
              <div>
                <input
                  type="text"
                  placeholder="Enter mobile number"
                  value={formData.phoneNumber}
                  maxLength={10}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                  className="w-full border p-2 rounded"
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
                )}
              </div>

              {/* Password */}
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Min 6 characters"
                  value={formData.password}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  className="w-full border p-2 rounded pr-10"
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-2 right-2 cursor-pointer text-gray-500"
                >
                  {showPassword ? (
                    <RxEyeClosed size={20} />
                  ) : (
                    <RxEyeOpen size={20} />
                  )}
                </span>
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>

              {/* Password Conditions */}
              <div className="text-sm space-y-1 flex gap-4 flex-wrap items-baseline">
                <p
                  className={`${
                    passwordConditions.minLength
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  ✅ 6+ characters
                </p>
                <p
                  className={`${
                    passwordConditions.upperLowercase
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  ✅ Upper and lowercase letters
                </p>
                <p
                  className={`${
                    passwordConditions.number
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  ✅ One number
                </p>
                <p
                  className={`${
                    passwordConditions.specialChar
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  ✅ One special character
                </p>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-orange-600 text-white py-2 rounded hover:bg-orange-700"
              >
                Create Account
              </button>
            </form>

            {/* Footer */}
            <p className="text-sm text-gray-600 mt-4 text-center">
              By clicking on Create Account, you agree to Delhivery's{" "}
              <a href="#" className="text-orange-500 underline">
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a href="#" className="text-orange-500 underline">
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CreateAccount;
