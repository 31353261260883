import React, { useState, useEffect } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
);

const Dashboard = () => {
  // Static data for charts
  const lineData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Revenue",
        data: [3000, 5000, 4000, 7000, 6000, 8000],
        borderColor: "#4F46E5",
        backgroundColor: "rgba(79, 70, 229, 0.3)",
        tension: 0.4,
      },
    ],
  };

  const doughnutData = {
    labels: ["Electronics", "Fashion", "Groceries", "Furniture"],
    datasets: [
      {
        data: [30, 25, 20, 25],
        backgroundColor: ["#6366F1", "#F59E0B", "#10B981", "#EF4444"],
        borderWidth: 0,
      },
    ],
  };

  const barData = {
    labels: ["Q1", "Q2", "Q3", "Q4"],
    datasets: [
      {
        label: "Customer Growth",
        data: [100, 200, 300, 400],
        backgroundColor: "#F97316",
      },
    ],
  };

  // Animation state for counting numbers
  const [revenue, setRevenue] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [orders, setOrders] = useState(0);
  const [profit, setProfit] = useState(0);

  // UseEffect for animating numbers
  useEffect(() => {
    const animateValue = (start, end, duration, setState) => {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const value = Math.floor(progress * (end - start) + start);
        setState(value);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    };

    // Trigger animations for all the counters
    animateValue(0, 45000, 2000, setRevenue);
    animateValue(0, 1200, 2000, setCustomers);
    animateValue(0, 750, 2000, setOrders);
    animateValue(0, 15000, 2000, setProfit);
  }, []);

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Dashboard</h1>

      {/* Status Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-10">
        <div className="bg-white shadow rounded-lg p-8">
          <h2 className="text-xl font-semibold text-gray-800">Total Revenue</h2>
          <p className="text-4xl font-bold text-blue-600">${revenue}</p>
        </div>
        <div className="bg-white shadow rounded-lg p-8">
          <h2 className="text-xl font-semibold text-gray-800">New Customers</h2>
          <p className="text-4xl font-bold text-green-500">{customers}</p>
        </div>
        <div className="bg-white shadow rounded-lg p-8">
          <h2 className="text-xl font-semibold text-gray-800">Orders</h2>
          <p className="text-4xl font-bold text-orange-500">{orders}</p>
        </div>
        <div className="bg-white shadow rounded-lg p-8">
          <h2 className="text-xl font-semibold text-gray-800">Profit</h2>
          <p className="text-4xl font-bold text-red-500">${profit}</p>
        </div>
      </div>

      {/* Charts */}
      <div className="space-y-10 flex flex-col justify-center items-center">
        {/* Line Chart */}
        <div
          className="bg-white shadow rounded-lg p-8 w-100"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h3 className="text-lg font-semibold text-gray-800 mb-6">
            Monthly Revenue
          </h3>
          <div
            className="h-[400px] w-full"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Line data={lineData} options={{ responsive: true }} />
          </div>
        </div>

        {/* Doughnut Chart */}
        <div
          className="bg-white shadow rounded-lg p-8 flex flex-col lg:flex-row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            className="h-[400px] w-full lg:w-1/2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Doughnut data={doughnutData} options={{ responsive: true }} />
          </div>

          {/* Static data on the right side */}
          <div className="w-full lg:w-1/2 mt-6 lg:mt-0 lg:pl-8" style={{padding:"10%"}}>
            <h4 className="text-lg font-bold text-gray-800 mb-6 border-b pb-2">
              Sales Summary
            </h4>
            <ul className="space-y-4">
              <li className="flex items-center gap-3">
                <span className="text-sm font-medium text-gray-500">
                  Total Sales:
                </span>
                <span className="text-md font-semibold text-gray-900">
                  1,245
                </span>
              </li>
              <li className="flex items-center gap-3">
                <span className="text-sm font-medium text-gray-500">
                  Sales Growth:
                </span>
                <span className="text-md font-semibold text-gray-900">12%</span>
              </li>
              <li className="flex items-center gap-3">
                <span className="text-sm font-medium text-gray-500">
                  Highest Selling Product:
                </span>
                <span className="text-md font-semibold text-gray-900">
                  Product A
                </span>
              </li>
              <li className="flex items-center gap-3">
                <span className="text-sm font-medium text-gray-500">
                  Lowest Selling Product:
                </span>
                <span className="text-md font-semibold text-gray-900">
                  Product C
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* Bar Chart */}
        <div
          className="bg-white shadow rounded-lg p-8"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h3 className="text-lg font-semibold text-gray-800 mb-6">
            Quarterly Growth
          </h3>
          <div
            className="h-[400px] w-full"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Bar data={barData} options={{ responsive: true }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
