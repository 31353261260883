import { useState } from "react";

const ImmediateComponent = () => {
  const [formData, setFormData] = useState({
    pickupPincode: "",
    deliveryPincode: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    paymentMode: "Prepaid",
    shipmentValue: "",
  });
  const [errors, setErrors] = useState({});
  const [areaSuggestions, setAreaSuggestions] = useState({
    pickupArea: [],
    deliveryArea: [],
  });

  const [coordinates, setCoordinates] = useState({
    pickup: { lat: null, lon: null },
    delivery: { lat: null, lon: null },
  });

  const [selectedAreas, setSelectedAreas] = useState({
    pickupPincode: "",
    deliveryPincode: "",
  });

  const validate = () => {
    const newErrors = {};

    // Pincode validation (6 digits)
    if (!formData.pickupPincode || !/^\d{6}$/.test(formData.pickupPincode)) {
      newErrors.pickupPincode =
        "Please enter a valid 6-digit Pickup Area Pincode.";
    }
    if (
      !formData.deliveryPincode ||
      !/^\d{6}$/.test(formData.deliveryPincode)
    ) {
      newErrors.deliveryPincode =
        "Please enter a valid 6-digit Delivery Area Pincode.";
    }

    if (
      !formData.weight ||
      isNaN(formData.weight) ||
      parseFloat(formData.weight) <= 0
    ) {
      newErrors.weight = "Weight must be a positive number.";
    }

    if (
      formData.length &&
      (isNaN(formData.length) || parseFloat(formData.length) <= 0)
    ) {
      newErrors.length = "Length must be a positive number.";
    }
    if (
      formData.width &&
      (isNaN(formData.width) || parseFloat(formData.width) <= 0)
    ) {
      newErrors.width = "Width must be a positive number.";
    }
    if (
      formData.height &&
      (isNaN(formData.height) || parseFloat(formData.height) <= 0)
    ) {
      newErrors.height = "Height must be a positive number.";
    }

    if (
      formData.shipmentValue &&
      (isNaN(formData.shipmentValue) || parseFloat(formData.shipmentValue) < 0)
    ) {
      newErrors.shipmentValue =
        "Shipment Value must be a valid non-negative number.";
    }

    if (!formData.paymentMode) {
      newErrors.paymentMode = "Please select a payment mode.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if (name === "pickupPincode" || name === "deliveryPincode") {
      // Limit the value to 6 digits
      const trimmedValue = value.slice(0, 6);
      setFormData((prev) => ({ ...prev, [name]: trimmedValue }));

      if (trimmedValue.length === 6) {
        try {
          const response = await fetch(
            `https://geocode.maps.co/search?q=${trimmedValue}&api_key=67614ab43301f501318716dku96bfc9`
          );
          const data = await response.json();

          const location = data[0];
          console.log(
            `${name} data --> Lat: ${location.lat}, Lon: ${location.lon}`
          );

          setCoordinates((prev) => ({
            ...prev,
            [name === "pickupPincode" ? "pickup" : "delivery"]: {
              lat: parseFloat(location.lat),
              lon: parseFloat(location.lon),
            },
          }));

          const suggestions = Array.isArray(data)
            ? data.map((item) => item.display_name)
            : [];
          setAreaSuggestions((prev) => ({ ...prev, [name]: suggestions }));
        } catch (error) {
          console.error("Error fetching area suggestions:", error);
          setAreaSuggestions((prev) => ({ ...prev, [name]: [] }));
        }
      } else {
        setAreaSuggestions((prev) => ({ ...prev, [name]: [] }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAreaSelect = (name, area) => {
    setSelectedAreas((prev) => ({ ...prev, [name]: area }));
    setAreaSuggestions((prev) => ({ ...prev, [name]: [] }));
  };

  //   const calculateDistance = async (area1, area2) => {
  //     try {
  //       const response = await fetch(
  //         `https://geocode.maps.co/distance?locations=${encodeURIComponent(
  //           area1
  //         )}|${encodeURIComponent(area2)}`
  //       );
  //       const data = await response.json();
  //       return data.distance; // Adjust based on the API's distance calculation response
  //     } catch (error) {
  //       console.error("Error calculating distance:", error);
  //       return null;
  //     }
  //   };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degrees) => (degrees * Math.PI) / 180;

    const R = 6371;
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lon2 - lon1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance;
  };

  const calculateCharges = () => {
    const weightCost = parseFloat(formData.weight) * 10 || 0;
    const length = parseFloat(formData.length) || 0;
    const width = parseFloat(formData.width) || 0;
    const height = parseFloat(formData.height) || 0;
    const volumeCost = length * width * height * 0.5 || 0;
    const shipmentValueCost =
      (parseFloat(formData.shipmentValue) / 100) * 5 || 0;

    let baseCost = 50;

    const remoteSurcharge =
      formData.pickupPincode.startsWith("1") ||
      formData.deliveryPincode.startsWith("1") ||
      formData.pickupPincode.startsWith("2") ||
      formData.deliveryPincode.startsWith("2")
        ? 20
        : 0;

    const totalCost =
      baseCost + weightCost + volumeCost + shipmentValueCost + remoteSurcharge;

    return totalCost.toFixed(2);
  };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     if (validate()) {
  //       // Proceed with form submission logic (e.g., call API or state update)
  //       const calculatedValue = calculateCharges();
  //       console.log("Calculated Value:", calculatedValue);

  //     //   if (selectedAreas.pickupPincode && selectedAreas.deliveryPincode) {
  //     //     const distance = await calculateDistance(
  //     //       selectedAreas.pickupPincode,
  //     //       selectedAreas.deliveryPincode
  //     //     );
  //     //     console.log(`Distance between areas: ${distance} km`);
  //     //   } else {
  //     //     console.error("Both areas must be selected");
  //     //   }
  //       //   console.log("Form submitted:", formData);
  //     }
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const { pickup, delivery } = coordinates;
      if (pickup.lat && pickup.lon && delivery.lat && delivery.lon) {
        const distance = calculateDistance(
          pickup.lat,
          pickup.lon,
          delivery.lat,
          delivery.lon
        );
        console.log(`Distance between areas: ${distance.toFixed(2)} km`);
      } else {
        console.error(
          "Coordinates for both locations are required to calculate distance."
        );
      }

      const calculatedValue = calculateCharges();
      console.log("Calculated Value:", calculatedValue);
    }
  };

  const handleReset = () => {
    setFormData({
      pickupPincode: "",
      deliveryPincode: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      paymentMode: "Prepaid",
      shipmentValue: "",
    });
    setAreaSuggestions({ pickupArea: [], deliveryArea: [] });
  };

  return (
    <section className="">
      <div className="flex flex-wrap md:flex-nowrap gap-8 items-center">
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4 md:p-8 w-full md:w-[70%]"
        >
          <div className="relative">
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Pick-up Area Pincode
              <span className="text-red-500 font-bold">*</span>
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="pickupPincode"
              value={formData.pickupPincode}
              onChange={handleInputChange}
              maxLength="6"
              placeholder="Enter 6 digit Pickup Area Pincode"
              className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
            />
            {errors.pickupPincode && (
              <p className="text-red-500 text-xs">{errors.pickupPincode}</p>
            )}
            {Array.isArray(areaSuggestions.pickupPincode) &&
              areaSuggestions.pickupPincode.length > 0 && (
                <ul className="absolute bg-white border border-gray-300 rounded-lg shadow-lg mt-1 w-full z-10 max-h-40 overflow-y-auto scrollbar-hidden">
                  {areaSuggestions.pickupPincode.map((area, index) => (
                    <li
                      key={index}
                      onClick={() => handleAreaSelect("pickupPincode", area)}
                      className="px-4 py-2 hover:bg-orange-100 cursor-pointer"
                    >
                      {area}
                    </li>
                  ))}
                </ul>
              )}
          </div>

          <div className="relative">
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Delivery Area Pincode
              <span className="text-red-500 font-bold">*</span>
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="deliveryPincode"
              value={formData.deliveryPincode}
              onChange={handleInputChange}
              maxLength="6"
              placeholder="Enter 6 digit Delivery Area Pincode"
              className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
            />
            {Array.isArray(areaSuggestions.deliveryPincode) &&
              areaSuggestions.deliveryPincode.length > 0 && (
                <ul className="absolute bg-white border border-gray-300 rounded-lg shadow-lg mt-1 w-full z-10 max-h-40 overflow-y-auto scrollbar-hidden">
                  {areaSuggestions.deliveryPincode.map((area, index) => (
                    <li
                      key={index}
                      onClick={() => handleAreaSelect("deliveryPincode", area)}
                      className="px-4 py-2 hover:bg-orange-100 cursor-pointer"
                    >
                      {area}
                    </li>
                  ))}
                </ul>
              )}

            {errors.deliveryPincode && (
              <p className="text-red-500 text-xs">{errors.deliveryPincode}</p>
            )}

            {/* Show area suggestions for Delivery Area */}
            {Array.isArray(areaSuggestions.deliveryArea) &&
              areaSuggestions.deliveryArea.length > 0 && (
                <ul className="absolute bg-white border border-gray-300 rounded-lg shadow-lg mt-1 w-full z-10">
                  {areaSuggestions.deliveryArea.map((area, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 hover:bg-orange-100 cursor-pointer"
                    >
                      {area}
                    </li>
                  ))}
                </ul>
              )}
          </div>

          {/* Remaining   */}
          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Weight<span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex">
              <input
                onWheel={(e) => e.target.blur()}
                type="number"
                name="weight"
                value={formData.weight}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border outline-none rounded-l-lg focus:ring-2 focus:ring-orange-500"
              />
              <span className="px-4 py-2 bg-gray-100 border rounded-r-lg">
                KG
              </span>
            </div>
            {errors.weight && (
              <p className="text-red-500 text-xs">{errors.weight}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Dimensions (Optional)
            </label>
            <div className="flex gap-2">
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="length"
                value={formData.length}
                onChange={handleInputChange}
                placeholder="Length"
                className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
              />
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="width"
                value={formData.width}
                onChange={handleInputChange}
                placeholder="Width"
                className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
              />
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="height"
                value={formData.height}
                onChange={handleInputChange}
                placeholder="Height"
                className="w-full px-4 py-2 border outline-none rounded-lg focus:ring-2 focus:ring-orange-500"
              />
            </div>
            {errors.length && (
              <p className="text-red-500 text-xs">{errors.length}</p>
            )}
            {errors.width && (
              <p className="text-red-500 text-xs">{errors.width}</p>
            )}
            {errors.height && (
              <p className="text-red-500 text-xs">{errors.height}</p>
            )}
          </div>

          {/* Payment Mode and Shipment Value */}
          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Payment Mode<span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex gap-4">
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="paymentMode"
                  value="Prepaid"
                  checked={formData.paymentMode === "Prepaid"}
                  onChange={handleInputChange}
                  className="focus:ring-purple-500"
                />
                Prepaid
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Shipment Value
            </label>
            <input
              onWheel={(e) => e.target.blur()}
              type="number"
              name="shipmentValue"
              value={formData.shipmentValue}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border outline-none Shipment Value rounded-lg focus:ring-2 focus:ring-orange-500"
            />
            {errors.shipmentValue && (
              <p className="text-red-500 text-xs">{errors.shipmentValue}</p>
            )}
          </div>

          <div className="mt-2 flex gap-4">
            <button
              type="submit"
              className="px-6 py-2 bg-orange-500 text-white rounded-lg"
            >
              Calculate
            </button>
            <button
              type="button"
              className="px-6 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
      {/* </div> */}
    </section>
  );
};

export default ImmediateComponent;
