import React, { useState } from "react";
import homepage from "../assets/HomeImage/homepage.webp";
import homepageMoblie from "../assets/HomeImage/homepage-mobile.webp";
import { CiLocationOn } from "react-icons/ci";
import Timeline from "./Timeline";
import OTPModal from "./Otpmodal";

const Tracking = () => {
  const [activeTab, setActiveTab] = useState("trackOrder");
  const [activeOption, setActiveOption] = useState("mobile");
  const [inputValue, setInputValue] = useState("");
  const [pickupPin, setPickupPin] = useState("");
  const [deliveryPin, setDeliveryPin] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTabChange = (tab) => setActiveTab(tab);
  const handleOptionChange = (option) => setActiveOption(option);

  const handleGetOtp = () => {
    setIsModalOpen(true);
    console.log(
      "API called with input: ",
      inputValue,
      "Option: ",
      activeOption
    );
  };

  const shipApiCall = () => {
    if (pickupPin && deliveryPin) {
      console.log("API call initiated with:", {
        pickupPin,
        deliveryPin,
      });
      alert("API hit successful!");
    } else {
      alert("Please fill in both pin codes!");
    }
  };

  const placeholderMap = {
    mobile: "Enter your mobile number",
    awb: "Enter your AWB number",
    orderId: "Enter your order ID",
    lrn: "Enter your LRN number",
  };

  return (
    <>
      {isModalOpen && (
        <OTPModal
          phoneNumber="8318709241"
          onClose={() => setIsModalOpen(false)}
          
        />
      )}
      <div className="p-6 relative ">
        {/* Left Section (Background and Text Content) */}

        <div className="">
          {/* Desktop Background */}
          <div
            className="d2None lg:block bg-cover text-white p-8 flex flex-col justify-center"
            style={{
              backgroundImage: `url(${homepage})`,
              height: "80vh",
              display: "flex"
            }}
          >
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
              We are India&#39;s largest fully integrated{" "}
              <br className="hidden md:block" />
              <span className="text-red-600">logistics services</span> provider
            </h1>
            <div className="text-sm md:text-xl flex flex-wrap items-center space-x-2 md:space-x-4">
              <p>Express Parcel</p>
              <p>
                <span className="inline-block w-2 h-2 bg-red-600 mr-2 mb-1"></span>{" "}
                PTL
              </p>
              <p>
                <span className="inline-block w-2 h-2 bg-red-600 mr-2 mb-1"></span>{" "}
                FTL
              </p>
              <p>
                <span className="inline-block w-2 h-2 bg-red-600 mr-2 mb-1"></span>{" "}
                Cross Border
              </p>
              <p>
                <span className="inline-block w-2 h-2 bg-red-600 mr-2 mb-1"></span>{" "}
                Supply Chain
              </p>
            </div>
          </div>

          {/* Mobile Background */}
          <div
            className="block lg:hidden bg-cover text-white p-8 flex flex-col "
            style={{
              backgroundImage: `url(${homepageMoblie})`,
              alignItems:" flex-end",
              minHeight: "42.25rem",
            }}
          >
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
              We are India&#39;s largest fully integrated{" "}
              <br className="hidden md:block" />
              <span className="text-red-600">logistics services</span> provider
            </h1>
            <div className="text-sm md:text-xl flex flex-wrap items-center space-x-2 md:space-x-4">
              <p>Express Parcel</p>
              <p>
                <span className="inline-block w-2 h-2 bg-red-600 mr-2 mb-1"></span>{" "}
                PTL
              </p>
              <p>
                <span className="inline-block w-2 h-2 bg-red-600 mr-2 mb-1"></span>{" "}
                FTL
              </p>
              <p>
                <span className="inline-block w-2 h-2 bg-red-600 mr-2 mb-1"></span>{" "}
                Cross Border
              </p>
              <p>
                <span className="inline-block w-2 h-2 bg-red-600 mr-2 mb-1"></span>{" "}
                Supply Chain
              </p>
            </div>
          </div>
        </div>

        {/* Functional Section (Tabs, Options, and Input Field) */}
        <div
          className="absolute formDiv bg-white w-full sm:w-11/12 md:w-1/2 lg:w-1/3 p-6 sm:p-8 flex flex-col justify-center shadow-lg rounded-lg"
          style={{
            top: "50%",
            right: "5%",
            transform: "translate(0%, -50%)",
          }}
        >
          {/* Tab Navigation */}
          <div className="border-b border-gray-300 mb-6 flex">
            <button
              onClick={() => handleTabChange("trackOrder")}
              className={`flex-1 pb-2 text-center font-medium text-base md:text-lg ${
                activeTab === "trackOrder"
                  ? "border-b-2 border-orange-600 text-orange-600"
                  : "text-gray-500"
              }`}
            >
              Track order
            </button>
            <button
              onClick={() => handleTabChange("shipOrder")}
              className={`flex-1 pb-2 text-center font-medium text-base md:text-lg ${
                activeTab === "shipOrder"
                  ? "border-b-2 border-orange-600 text-orange-600"
                  : "text-gray-500"
              }`}
            >
              Ship order
            </button>
          </div>

          {activeTab === "trackOrder" && (
            <div>
              <h2 className="text-lg md:text-2xl  mb-4">
                <strong> Track </strong> your order through
              </h2>

              {/* Options */}
              <div className="flex flex-wrap mb-4 ">
                {[
                  { label: "Mobile", value: "mobile" },
                  { label: "AWB", value: "awb" },
                  { label: "Order Id", value: "orderId" },
                  { label: "LRN", value: "lrn" },
                ].map((option) => (
                  <button
                    key={option.value}
                    onClick={() => handleOptionChange(option.value)}
                    className={`flex-1 min-w-[80px] text-center py-2 font-medium ${
                      activeOption === option.value
                        ? "bg-black text-white"
                        : "bg-white text-black border border-gray-300"
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>

              {/* Input Field */}
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={placeholderMap[activeOption]}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-red-600"
              />

              {/* Get OTP Button */}
              <button
                onClick={handleGetOtp}
                className="w-full bg-orange-600 text-white py-3 rounded-lg shadow-lg hover:bg-orange-700 transition duration-300"
              >
                {activeOption === "mobile" ? (
                  <p> Get OTP</p>
                ) : (
                  <p> Track Order </p>
                )}
              </button>
            </div>
          )}

          {activeTab === "shipOrder" && (
            <div className="">
              <h2 className="text-lg md:text-2xl  mb-0 ml-8">
                <strong>Ship </strong> your order
              </h2>

              {/* Input Fields with Circle Icon */}
              <div className="relative flex flex-col space-y-4 mb-2">
                <div className="absolute left-[-15px] top-7 flex flex-col items-center space-y-1">
                  <div className="flex items-center justify-center w-4 h-4 bg-gray-400 rounded-full">
                    <span className="text-white text-xs">●</span>
                  </div>
                  <div className="w-[2px] h-9 border-l-2 border-dotted border-gray-300"></div>

                  <CiLocationOn />
                </div>

                {/* Pickup Pin Code Input */}
                <div className="pl-8">
                  <input
                    type="text"
                    value={pickupPin}
                    onChange={(e) => setPickupPin(e.target.value)}
                    placeholder="Enter pickup pin code"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600"
                  />
                </div>

                {/* Delivery Pin Code Input */}
                <div className="pl-8">
                  <input
                    type="text"
                    value={deliveryPin}
                    onChange={(e) => setDeliveryPin(e.target.value)}
                    placeholder="Enter delivery pin code"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600"
                  />
                </div>
              </div>

              {/* Ship Now Button */}
              <div className="mt-3">
                <button
                  onClick={shipApiCall}
                  className="w-11/12 ml-[25px] md:ml-8 bg-orange-600 text-white py-3 rounded-lg shadow-lg hover:bg-orange-700 transition duration-300"
                >
                  Ship Now
                </button>
              </div>
              {/* Footer Links */}
            </div>
          )}
        </div>
      </div>
      <Timeline />
    </>
  );
};

export default Tracking;
