/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import config from "../../config";
import { Modal as AntModal, Input, Form, message, Select, Button } from "antd";
import axios from "axios";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import Styles from "../Qwikco_order/SellerDetails.module.css";

const basicDetails = ({ handleNext }) => {
  // const router = useRouter();
  const { baseUrl } = config;

  const [buyerInfo, setBuyerInfo] = useState({
    name: "",
    email: "",
    contact_no: "",
    delivery_state: "",
    delivery_contact_person: "",
    delivery_contact_number: "",
    delivery_email: "",
    delivery_alternate_phone: "",
    delivery_complete_address: "",
    delivery_gps: "",
    latitude: "",
    longitude: "",
    delivery_landmark: "",
    delivery_pincode: "",
    delivery_city: "",
    delivery_country: "INDIA",
    billing_mobile_no: "",
    billing_full_name: "",
    billing_email: "",
    billing_complete_address: "",
    billing_landmark: "",
    billing_pincode: "",
    billing_city: "",
    billing_state: "",
    billing_country: "IND",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];

  const [basicInfoError, setBuyerInfoError] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState({});
  const [thankYou, setThankYou] = useState(false);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setBuyerInfo({ ...buyerInfo, [name]: value });

    if (value.trim() !== "") {
      setValidationErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateFields = () => {
    const errors = {};
  
    // General Information
    if (!buyerInfo.name) errors.name = "Full Name is required.";
    if (!buyerInfo.email) {
      errors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(buyerInfo.email)) {
      errors.email = "Please enter a valid email address.";
    }
    
    if (!buyerInfo.contact_no) {
      errors.contact_no = "Mobile Number is required.";
    } else if (!/^\d{10}$/.test(buyerInfo.contact_no)) {
      errors.contact_no = "Mobile Number must be exactly 10 digits.";
    }
  
    // Delivery Information
    if (!buyerInfo.delivery_contact_person)
      errors.delivery_contact_person = "Delivery Contact Person is required.";
    if (!buyerInfo.delivery_contact_number)
      errors.delivery_contact_number = "Delivery Contact Number is required.";
    if (!buyerInfo.delivery_state)
      errors.delivery_state = "Delivery State is required.";
    if (!buyerInfo.delivery_complete_address)
      errors.delivery_complete_address = "Delivery Address is required.";
    if (!buyerInfo.delivery_pincode) {
      errors.delivery_pincode = "Delivery Pincode is required.";
    } else if (!/^\d{6}$/.test(buyerInfo.delivery_pincode)) {
      errors.delivery_pincode = "Delivery Pincode must be a valid 6-digit number.";
    }
    if (!buyerInfo.delivery_city)
      errors.delivery_city = "Delivery City is required.";
  
    // Billing Information
    if (!buyerInfo.billing_mobile_no) {
      errors.billing_mobile_no = "Billing Mobile Number is required.";
    } else if (!/^\d{10}$/.test(buyerInfo.billing_mobile_no)) {
      errors.billing_mobile_no = "Billing Mobile Number must be exactly 10 digits.";
    }
    if (!buyerInfo.billing_full_name) {
      errors.billing_full_name = "Billing Full Name is required.";
    }
    if (buyerInfo.billing_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(buyerInfo.billing_email)) {
      errors.billing_email = "Invalid Email Address.";
    }
    if (!buyerInfo.billing_complete_address) {
      errors.billing_complete_address = "Billing Complete Address is required.";
    }
    if (!buyerInfo.billing_pincode) {
      errors.billing_pincode = "Billing Pincode is required.";
    } else if (!/^\d{6}$/.test(buyerInfo.billing_pincode)) {
      errors.billing_pincode = "Billing Pincode must be a valid 6-digit number.";
    }
    if (!buyerInfo.billing_city) {
      errors.billing_city = "Billing City is required.";
    }
    if (!buyerInfo.billing_state) {
      errors.billing_state = "Billing State is required.";
    }
  
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleSaveBasicInfo = () => {
    console.log("buyerinfo----->", buyerInfo);
    if (validateFields()) {
      try {
        const jsonData = JSON.stringify(buyerInfo);
        const encodedData = CryptoJS.enc.Base64.stringify(
          CryptoJS.enc.Utf8.parse(jsonData)
        );
        localStorage.setItem("buyerInfo", encodedData);
        handleNext();
      } catch (error) {
        console.error("Error saving to localStorage:", error.message);
      }
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setBuyerInfo((prev) => ({ ...prev, latitude, longitude }));
        },
        (error) => console.error("Geolocation error:", error.message)
      );
    }
  }, []);

  useEffect(() => {
    try {
      const savedBuyerInfo = localStorage.getItem("buyerInfo");
      if (savedBuyerInfo) {
        console.log("Retrieved data from localStorage:", savedBuyerInfo);
        const decodedData = CryptoJS.enc.Base64.parse(savedBuyerInfo).toString(
          CryptoJS.enc.Utf8
        );
        const parsedData = JSON.parse(decodedData);
        setBuyerInfo(parsedData);
      }
    } catch (error) {
      console.error("Error loading from localStorage:", error.message);
    }
  }, []);
  // console.log("buyerInfo", buyerInfo);
  return (
    <>
      <div class="row w-full">
        <div class="container mx-auto p-6 w-full">
          <div class="mb-6">
            <h3 class="text-lg font-semibold text-gray-700">
              To whom is the order being delivered ?
              <small className="text-gray-400 text-[10px]">
                (Buyer's Info)
              </small>
            </h3>
          </div>
          <div class="bg-white p-6 shadow rounded-md w-full">
            <div class="mb-6">
              <h3 class="text-lg font-semibold text-gray-700">Customer Info</h3>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
              {/* <!-- Full Name --> */}
              <div>
                <label
                  for="seller_name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Full Name <span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="seller_name"
                  name="name"
                  className={`border ${
                    validationErrors.name ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter Name"
                  value={buyerInfo?.name}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.name && (
                  <p className="text-red-500 text-xs mt-1">
                    {validationErrors.name}
                  </p>
                )}
              </div>
              {/* <!-- Email --> */}
              <div>
                <label
                  for="seller_email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email <span class="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="seller_email"
                  name="email"
                  className={`border ${
                    validationErrors.email ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter Email"
                  value={buyerInfo?.email}
                  onChange={(e) => inputHandler(e)}
                />
                  {validationErrors.email && (
                  <p className="text-red-500 text-xs mt-1">
                    {validationErrors.email}
                  </p>
                )}
              </div>

              {/* <!-- Mobile Number --> */}
              <div>
                <label
                  for="seller_contact"
                  class="block text-sm font-medium text-gray-700"
                >
                  Mobile Number <span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="seller_contact"
                  name="contact_no"
                  className={`border ${
                    validationErrors.contact_no
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter Contact No"
                  value={buyerInfo?.contact_no}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.contact_no && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.contact_no}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div class="bg-white p-6 shadow rounded-md mt-6 w-full">
            <div class="mb-6">
              <h3 class="text-lg font-semibold text-gray-700">
                Where is the order being Delivered to ?
                <small className="text-gray-400 text-[10px]">
                  (Customer Details)
                </small>
              </h3>
            </div>

            {/* <!-- Other Details Section --> */}
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
              {/* <!-- Delivery Contact Person --> */}
              <div>
                <label
                  for="delivery_contact_person"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery Person Name<span class="text-red-500">*</span>
                </label>
                <input
                  type="text" // text
                  id="delivery_contact_person"
                  name="delivery_contact_person"
                  className={`border ${
                    validationErrors.delivery_contact_person
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter Contact Person Name"
                  value={buyerInfo?.delivery_contact_person}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.delivery_contact_person && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.delivery_contact_person}
                  </p>
                )}
              </div>

              {/* <!-- Delivery Contact Number --> */}
              <div>
                <label
                  for="delivery_contact_number"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery Contact Number <span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="delivery_contact_number"
                  name="delivery_contact_number"
                  className={`border ${
                    validationErrors.delivery_contact_number
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter Contact Number"
                  value={buyerInfo?.delivery_contact_number}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.delivery_contact_number && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.delivery_contact_number}
                  </p>
                )}
              </div>

              {/* <!-- Delivery Email --> */}
              <div>
                <label
                  for="delivery_email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery Email (optional) <span class="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="delivery_email"
                  name="delivery_email"
                  class="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Delivery Email"
                  value={buyerInfo?.delivery_email}
                  onChange={(e) => inputHandler(e)}
                />
              </div>

              {/* <!-- Delivery Alternate Phone --> */}
              <div>
                <label
                  for="delivery_alternate_phone"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery Alternate Phone
                </label>
                <input
                  type="text"
                  id="delivery_alternate_phone"
                  name="delivery_alternate_phone"
                  className={`border ${
                    validationErrors.delivery_alternate_phone
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter Alternate Phone"
                  value={buyerInfo?.delivery_alternate_phone}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.delivery_alternate_phone && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.delivery_alternate_phone}
                  </p>
                )}
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">
                  Select State <span class="text-red-500">*</span>
                </label>
                <select
                  name="delivery_state"
                  className={`border ${
                    validationErrors.delivery_state
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  value={buyerInfo?.delivery_state}
                  onChange={(e) => inputHandler(e)}
                >
                  <option value="">Select State</option>
                  {indianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                {validationErrors.delivery_state && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.delivery_state}
                  </p>
                )}
              </div>

              {/* <!-- Delivery Complete Address --> */}
              <div>
                <label
                  for="delivery_complete_address"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery Complete Address <span class="text-red-500">*</span>
                </label>
                <input
                  // rows="3"
                  id="delivery_complete_address"
                  name="delivery_complete_address"
                  className={`border ${
                    validationErrors.delivery_complete_address
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter Complete Address"
                  value={buyerInfo?.delivery_complete_address}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.delivery_complete_address && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.delivery_complete_address}
                  </p>
                )}
              </div>

              {/* <!-- Delivery Landmark --> */}
              <div>
                <label
                  for="delivery_landmark"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery Landmark (optional)
                </label>
                <input
                  type="text"
                  id="delivery_landmark"
                  name="delivery_landmark"
                  class="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Landmark"
                  value={buyerInfo?.delivery_landmark}
                  onChange={(e) => inputHandler(e)}
                />
              </div>

              {/* Latitude Field */}
              <div>
                <label
                  htmlFor="latitude"
                  className="block text-sm font-medium text-gray-700"
                >
                  Latitude
                </label>
                <input
                  type="text"
                  id="latitude"
                  name="latitude"
                  className="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Latitude"
                  value={buyerInfo?.latitude}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.latitude && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.latitude}
                  </p>
                )}
              </div>

              {/* Longitude Field */}
              <div>
                <label
                  htmlFor="longitude"
                  className="block text-sm font-medium text-gray-700"
                >
                  Longitude
                </label>
                <input
                  type="text"
                  id="longitude"
                  name="longitude"
                  className="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Longitude"
                  value={buyerInfo?.longitude}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.longitude && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.longitude}
                  </p>
                )}
              </div>

              {/* <!-- Delivery Pin Code --> */}
              <div>
                <label
                  for="delivery_pincode"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery Pin Code <span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="delivery_pincode"
                  name="delivery_pincode"
                  className={`border ${
                    validationErrors.delivery_pincode
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter Pin Code"
                  value={buyerInfo?.delivery_pincode}
                  onChange={(e) => inputHandler(e)}
                />
              </div>

              {/* <!-- Delivery City --> */}
              <div>
                <label
                  for="delivery_city"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery City <span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="delivery_city"
                  name="delivery_city"
                  className={`border ${
                    validationErrors.delivery_city
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter City"
                  value={buyerInfo?.delivery_city}
                  onChange={(e) => inputHandler(e)}
                />
                {validationErrors.delivery_city && (
                  <p className="text-red-500 text-sm mt-1">
                    {validationErrors.delivery_city}
                  </p>
                )}
              </div>

              {/* <!-- Delivery Country --> */}
              <div>
                <label
                  for="delivery_country"
                  class="block text-sm font-medium text-gray-700"
                >
                  Delivery Country <span class="text-red-500">*</span>
                </label>
                <input
                  disabled
                  type="text"
                  id="delivery_country"
                  name="delivery_country"
                  class="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Country"
                  value={buyerInfo?.delivery_country}
                  onChange={(e) => inputHandler(e)}
                />
              </div>
            </div>
            {/* <!-- Terms and Conditions --> */}
          </div>

          <div className="bg-white p-6 shadow rounded-md mt-6 w-full">
            <h1 className="text-2xl font-bold mb-4">Billing Address</h1>
            {/* billing's Details */}
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    name="billing_mobile_no"
                    placeholder="+91 Enter buyer's phone number"
                    value={buyerInfo.billing_mobile_no}
                    onChange={(e) => inputHandler(e)}
                    className={`border ${
                      validationErrors.billing_mobile_no
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  />
                  {validationErrors.billing_mobile_no && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors.billing_mobile_no}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium">Full Name</label>
                  <input
                    type="text"
                    name="billing_full_name"
                    placeholder="Enter full name"
                    value={buyerInfo.billing_full_name}
                    onChange={(e) => inputHandler(e)}
                    className={`border ${
                      validationErrors.billing_full_name
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  />
                  {validationErrors.billing_full_name && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors.billing_full_name}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium">
                    Email Id (Optional)
                  </label>
                  <input
                    type="email"
                    name="billing_email"
                    placeholder="i.e abc@gmail.com"
                    value={buyerInfo.billing_email}
                    onChange={(e) => inputHandler(e)}
                    className="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>

            {/* billing's Address */}
            <div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="mt-4">
                  <label className="block text-sm font-medium">
                    Complete Address
                  </label>
                  <input
                    type="text"
                    name="billing_complete_address"
                    placeholder="House/Floor No. Building Name or Street, Locality"
                    value={buyerInfo.billing_complete_address}
                    onChange={(e) => inputHandler(e)}
                    className={`border ${
                      validationErrors.billing_complete_address
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  />
                  {validationErrors.billing_complete_address && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors.billing_complete_address}
                    </p>
                  )}
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium">
                    Landmark (Optional)
                  </label>
                  <input
                    type="text"
                    name="billing_landmark"
                    placeholder="Any nearby post office, market, hospital"
                    value={buyerInfo.billing_landmark}
                    onChange={(e) => inputHandler(e)}
                    className="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
                <div>
                  <label className="block text-sm font-medium">Pincode</label>
                  <input
                    type="text"
                    name="billing_pincode"
                    placeholder="Enter Buyer's Pincode"
                    value={buyerInfo.billing_pincode}
                    onChange={(e) => inputHandler(e)}
                    className="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                  />
                  {validationErrors.billing_pincode && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors.billing_pincode}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium">City</label>
                  <input
                    type="text"
                    name="billing_city"
                    placeholder="Enter Buyer's City"
                    value={buyerInfo.billing_city}
                    onChange={(e) => inputHandler(e)}
                    className={`border ${
                      validationErrors.billing_city
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                  />
                  {validationErrors.billing_city && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors.billing_city}
                    </p>
                  )}
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">
                    Select State <span class="text-red-500">*</span>
                  </label>
                  <select
                    name="billing_state"
                    className={`border ${
                      validationErrors.billing_state
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500`}
                    value={buyerInfo?.billing_state}
                    onChange={(e) => inputHandler(e)}
                  >
                    <option value="">Select State</option>
                    {indianStates.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {validationErrors.billing_state && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors.billing_state}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    for="delivery_country"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Delivery Country <span class="text-red-500">*</span>
                  </label>
                  <input
                    disabled
                    type="text"
                    id="delivery_country"
                    name="billing_country"
                    class="border-gray-300 rounded-md shadow-sm w-full px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter Country"
                    value={buyerInfo?.billing_country}
                    onChange={(e) => inputHandler(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          {thankYou ? (
            <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mt-6 w-full">
              <h3 class="text-2xl font-bold">Thank You!</h3>
              <p class="text-sm">
                Thank you for your interest! Check your email for a link to the
                guide.
              </p>
            </div>
          ) : (
            <div class="flex justify-end mt-6 w-full">
              <button
                type="button"
                class="bg-blue-500 text-white px-6 py-2 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                onClick={handleSaveBasicInfo}
              >
                Save & Next
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default basicDetails;
