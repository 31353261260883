import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";

const Greeting = ({ userData }) => {
  const FormattedDate = () => {
    const currentDate = moment();
    return <span>{currentDate.format("dddd, DD MMMM YYYY")}</span>;
  };

  return (
    <div className="flex items-end gap-2">
      <div className="hidden md:block">
        <h1 className="text-xs md:text-sm lg:text-xl font-medium capitalize">
          Welcome Back, {userData?.name}
        </h1>
        <p className="text-zinc-400 text-xs">Today is {FormattedDate()}</p>
      </div>
      <NavLink to="/dashboard">
        <img
          className="logoImage h-10 block sm:block md:hidden"
          src="/hero1.svg"
          alt="logo"
        />
      </NavLink>
      <div className="capitalize bg-blue-50 border-[1px] border-blue-200 rounded-md hidden md:block py-1 px-2 md:px-4 text-blue-500 text-xs">
        {userData?.role}
      </div>
    </div>
  );
};

export default Greeting;
