import React from 'react';
import PropTypes from 'prop-types';

const StepContent = ({ stepNum, stepTitle, pageDescription, children }) => (
  <div className="px-5 py-4 bg-white ">
    <div className="text-sm font-semibold text-gray-600">
      {/* Step {stepNum} / 3 */}
    </div>
    <div className="border-b pb-4 mb-4">
      <h5 className="text-lg font-bold text-gray-800">{stepTitle}</h5>
      <p className="text-sm text-gray-600 mt-2">{pageDescription}</p>
    </div>
    <div>{children}</div>
  </div>
);

StepContent.propTypes = {
  stepNum: PropTypes.number.isRequired,
  stepTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default StepContent;
