import React, { useState,useEffect } from "react";
import CryptoJS from 'crypto-js';

const PickupInfo = ({ handlePrevious, handleNext }) => {
  const [picupdata, setPicupData] = useState({
    tag: "Home",
    contactPerson: "",
    contactNumber: "",
    email: "",
    alternatePhone: "",
    address: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!picupdata.contactPerson.trim())
      newErrors.contactPerson = "Contact person is required.";
    if (
      !picupdata.contactNumber.trim() ||
      !/^\d{10}$/.test(picupdata.contactNumber)
    )
      newErrors.contactNumber = "Valid 10-digit phone number is required.";
    if (!picupdata.email.trim() || !/\S+@\S+\.\S+/.test(picupdata.email))
      newErrors.email = "Valid email address is required.";
    if (!picupdata.address.trim())
      newErrors.address = "Complete address is required.";
    if (!picupdata.pincode.trim() || !/^\d{6}$/.test(picupdata.pincode))
      newErrors.pincode = "Valid 6-digit pincode is required.";
    if (!picupdata.city.trim()) newErrors.city = "City is required.";
    if (!picupdata.state.trim()) newErrors.state = "State is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setPicupData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim()) {
        delete newErrors[name];
      } else {
        newErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)
          } is required`;
      }
      return newErrors;
    });
  };

  const handleTagChange = (tag) => {
    setPicupData({ ...picupdata, tag });
  };

  // const handleSubmit = () => {
  //   if (validate()) {
  //     console.log("Saved Address Data:", picupdata);
  //     handleNext();
  //   }
  // };
  const handleSavePickupInfo = () => {
    if (validate()) {
    try {
      const jsonData = JSON.stringify(picupdata);
      const encodedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(jsonData));
      localStorage.setItem("pickupinfo", encodedData);
      handleNext();
    } catch (error) {
      console.error("Error saving to localStorage:", error.message);
    }
  }
  };

  useEffect(() => {
    try {
      const savedBuyerInfo = localStorage.getItem("pickupinfo");
      if (savedBuyerInfo) {
        console.log("Retrieved data from localStorage:", savedBuyerInfo);
        const decodedData = CryptoJS.enc.Base64.parse(savedBuyerInfo).toString(CryptoJS.enc.Utf8);
        const parsedData = JSON.parse(decodedData);
        setPicupData(parsedData);
      }
    } catch (error) {
      console.error("Error loading from localStorage:", error.message);
    }
  }, []);

  return (
    <div className="max-w-full mx-auto bg-white p-6 rounded-lg shadow-lg">
      <h1 className="text-xl font-semibold mb-4">Add New Pick Up Address</h1>

  <div className="mb-4">
  <label className="block text-sm font-medium mb-2">
    Tag this address as
  </label>
  <div className="flex flex-wrap gap-4">
    {["Home", "Work", "Warehouse", "Other"].map((tag) => (
      <button 
        key={tag}
        onClick={() => handleTagChange(tag)}
        className={`px-4 py-2 rounded-lg border ${
          picupdata.tag === tag ? "bg-blue-500 text-white" : "bg-gray-100"
        }`}
      >
        {tag}
      </button>
    ))}
  </div>
</div>


      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium mb-1">
            Contact Person
          </label>
          <input
            type="text"
            name="contactPerson"
            value={picupdata.contactPerson}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-2 ${errors.contactPerson ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="Name of the person to be contacted"
          />
          {errors.contactPerson && (
            <p className="text-red-500 text-sm">{errors.contactPerson}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Contact Number
          </label>
          <input
            type="text"
            name="contactNumber"
            value={picupdata.contactNumber}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-2 ${errors.contactNumber ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="Enter 10 digit mobile number"
          />
          {errors.contactNumber && (
            <p className="text-red-500 text-sm">{errors.contactNumber}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Email Address
          </label>
          <input
            type="email"
            name="email"
            value={picupdata.email}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-2 ${errors.email ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="i.e abc@gmail.com"
          />
          {errors.email && (
            <p className="text-red-500 text-sm">{errors.email}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Alternate Phone No. (Optional)
          </label>
          <input
            type="text"
            name="alternatePhone"
            value={picupdata.alternatePhone}
            onChange={handleInputChange}
            className="w-full border rounded-lg px-4 py-2 border-gray-300"
            placeholder="Enter 10 digit mobile number"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium mb-1">
            Complete Address
          </label>
          <textarea
            name="address" rows={1}
            value={picupdata.address}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-2 ${errors.address ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="House/Floor No., Building Name or Street, Locality"
          />
          {errors.address && (
            <p className="text-red-500 text-sm">{errors.address}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Landmark</label>
          <input type="text" name="landmark" value={picupdata.landmark}
            onChange={handleInputChange} className="w-full border rounded-lg px-4 py-2 border-gray-300"
            placeholder="Nearby post office, market, hospital, etc."
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Pincode</label>
          <input
            type="text"
            name="pincode"
            value={picupdata.pincode}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-2 ${errors.pincode ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="Add Pincode"
          />
          {errors.pincode && (
            <p className="text-red-500 text-sm">{errors.pincode}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">City</label>
          <input
            type="text"
            name="city"
            value={picupdata.city}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-2 ${errors.city ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="City"
          />
          {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">State</label>
          <input
            type="text"
            name="state"
            value={picupdata.state}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-2 ${errors.state ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="State"
          />
          {errors.state && (
            <p className="text-red-500 text-sm">{errors.state}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Country</label>
          <input
            type="text"
            name="country"
            value={picupdata.country}
            onChange={handleInputChange}
            className="w-full border rounded-lg px-4 py-2 border-gray-300"
            readOnly
          />
        </div>
      </div>

      <div className="flex justify-end gap-2 items-center mt-4">
        <button
          type="button"
          onClick={handlePrevious}
          className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-gray-200 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
          previous
        </button>
        <button onClick={handleSavePickupInfo}
          type="button"
          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default PickupInfo;
