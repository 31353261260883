import React from 'react'

const Products = () => {
  return (
    <div className='p-6'>
      Products
      Products
    </div>
  )
}

export default Products
