import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Locations from "./pages/Locations";
import Services from "./pages/Services";
import Trackpackage from "./pages/Trackpackage";
import Review from "./pages/Review";
import Contact from "./pages/Contact";
import Trackparcel from "./pages/Trackparcel";
import HomePage from "./pages/HomePage";
import Tracking from "./pages/Tracking";
import CreateAccount from "./pages/Createaccount";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import DashboardLayout from "./dashboard/DashboardLayout";
import Dashboard from "./dashboard/Dashboard";
import Customers from "./dashboard/Customers";
import Products from "./dashboard/Products";
import Orders from "./dashboard/Orders";
import Analytics from "./dashboard/Analytics";
import Settings from "./dashboard/Settings";
import Segments from "./dashboard/Segments";
import AddOrder from "./dashboard/Qwikco_order";

function App() {
  return (
    <Router>
      <Routes>  
      <Route element={<DashboardLayout />} > 
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/products" element={<Products />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/customers/segments" element={<Segments />} />
      <Route path="/add_order" element={<AddOrder />} />
      
      </Route>

        <Route element={<Layout />} > 
        <Route path="/" element={<Tracking />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/location" element={<Locations />} />
        <Route path="/calculator" element={<Services />} />
        <Route path="/trackpackage" element={<Trackpackage />} />
        <Route path="/review" element={<Review />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/trackparcel" element={<Trackparcel />} />
        <Route path="/order_tracking" element={<Tracking />} />
        <Route path="/signup" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
