import React, { useState } from "react";
import { motion } from "framer-motion";
import courierImage from "../assets/HomeImage/courierimg.jpg";
import config from "../config";
import apiCall from "../reusable/Api";
import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

const Login = () => {
  const {baseUrl} =config
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = { email: "", password: "" };

    // Validation
    if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!password) {
      newErrors.password = "Password cannot be empty.";
    }

    setErrors(newErrors);

    if (!newErrors.email && !newErrors.password) {
      alert("Form submitted")
      try{
        const response = apiCall("POST",`${baseUrl}/auth/login`,{email,password})
        console.log("response",response)
        const {token} = response
        const decoded = jwtDecode(token)
        console.log("decoded",decoded)
        if(decoded.userExist){
          Navigate("/dashboard")
        }else{
          Navigate("/signup")
        }
      }catch(error){
        console.log("error",error)
      }
    }
  };

  console.log("baseUrl",baseUrl);
  return (
    <div className="flex flex-col md:flex-row h-[90] p-14 rounded-lg">
      {/* Left Section */}
      <div className="hidden md:flex relative w-1/2 rounded-l-lg">
        <img
          src={courierImage}
          alt="Courier Illustration"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-white p-6">
          <h1 className="text-4xl font-extrabold">Welcome Back!</h1>
          <p className="mt-4 text-center text-lg">
            Experience the Powerful Courier Management Software
            <br />
            By{" "}
            <span className="font-semibold text-orange-600">
              ENS Enterprises
            </span>
          </p>
        </div>
      </div>

      {/* Right Section with Framer Motion */}
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full md:w-1/2 p-1"
      >
        <div className="p-8 shadow-lg ">
          <h2 className="text-2xl text-center font-bold mb-6">
            Courier Management System <br /> Authority Login
          </h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Email Input */}
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-medium mb-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            {/* Password Input */}
            <div>
              <label
                htmlFor="password"
                className="block text-gray-700 font-medium mb-2"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">{errors.password}</p>
              )}
            </div>

            {/* Remember Me */}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="remember"
                className="mr-2 focus:ring-orange-500"
              />
              <label htmlFor="remember" className="text-gray-600 text-sm">
                Remember my preference
              </label>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-orange-600 text-white py-3 rounded-lg hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              Sign In
            </button>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;
