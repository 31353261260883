import React, { useState, useMemo } from "react";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const ProfileModal = ({ onClose }) => {
    const navigate = useNavigate()
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "XXXXXXXX9241",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = "Full Name is required.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Valid Email ID is required.";
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      alert("Profile submitted successfully!");
      onClose(); 
      navigate("/home")
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center backdrop-blur-sm bg-black bg-opacity-40"  >
      <div className="bg-white p-6 rounded-lg shadow-lg relative" style={{width:"30%"}}>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          <RxCross1 />
        </button>
        <h2 className="text-lg font-bold mb-4 text-center">Create Your Profile</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block">Full Name</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="w-full border rounded p-2"
              placeholder="Enter Full Name"
            />
            {errors.fullName && (
              <p className="text-red-500 text-sm">{errors.fullName}</p>
            )}
          </div>
          <div>
            <label className="block">Email ID</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full border rounded p-2"
              placeholder="Enter Email ID"
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email}</p>
            )}
          </div>
          <div>
            <label className="block">Phone Number</label>
            <input
              type="text"
              value={formData.phoneNumber}
              readOnly
              className="w-full border rounded p-2 bg-gray-100"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 rounded bg-orange-600 text-white hover:bg-orange-700"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const OTPModal = ({ phoneNumber, onClose }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isChecked, setIsChecked] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const otpRefs = useMemo(
    () =>
      Array(6)
        .fill(0)
        .map(() => React.createRef()),
    []
  );

  const handleInputChange = (value, index) => {
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleBackspace = (index) => {
    const newOtp = [...otp];
    newOtp[index] = "";
    setOtp(newOtp);

    if (index > 0) {
      otpRefs[index - 1].current.focus();
    }
  };

  const isSubmitEnabled = otp.every((digit) => digit !== "") && isChecked;

  const handleSubmit = () => {
    if (isSubmitEnabled) {
      alert(`OTP entered: ${otp.join("")}`);
      setIsProfileModalOpen(true); 
      
    }
  };

  return (
    <>
      {/* OTP Modal */}
      <div className="fixed inset-0 z-50 flex justify-center items-center backdrop-blur-sm bg-black bg-opacity-40">
        <div className="bg-white p-6 rounded-lg shadow-lg relative flex flex-col justify-center items-center">
          <button
            onClick={() => onClose(false)}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          >
            <RxCross1 />
          </button>

          <h2 className="text-lg font-bold mb-4">
            We have sent your One Time Password to +91 {phoneNumber}
          </h2>

          <div className="flex space-x-2 mb-4">
            {otp.map((digit, index) => (
              <input
                key={index}
                ref={otpRefs[index]}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange(e.target.value, index)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") handleBackspace(index);
                }}
                className="w-10 h-10 text-center border border-gray-300 rounded"
              />
            ))}
          </div>

          <div className="mb-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <span>
                I agree to{" "}
                <a href="#" className="text-blue-500">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="#" className="text-blue-500">
                  Terms and Conditions
                </a>
                .
              </span>
            </label>
          </div>

          <button
            onClick={handleSubmit}
            disabled={!isSubmitEnabled}
            className={`w-full py-3 rounded-lg ${
              isSubmitEnabled
                ? "bg-orange-600 text-white hover:bg-orange-700"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
          >
            Submit
          </button>
        </div>
      </div>

      {/* Profile Modal */}
      {isProfileModalOpen && (
        <ProfileModal onClose={() => {setIsProfileModalOpen(false) ; onClose(false)}} />
      )}
    </>
  );
};

export default OTPModal;
