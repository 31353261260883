import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { BiSolidDashboard } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import { GrSync } from "react-icons/gr";
import { FaClipboardList } from "react-icons/fa";
import { RiShoppingCart2Line } from "react-icons/ri";
import { IoAppsSharp } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { PiArrowBendDownRightLight } from "react-icons/pi";
import logo from "../assets/HomeImages/Q2.png";
import logo2 from "../assets/HomeImage/q2logo.png"


const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [isCollapsed, setIsCollapsed] = useState(true);

  const NavigationData = [
    {
      title: "Dashboard",
      icon: <BiSolidDashboard />,
      location: "/dashboard",
    },
    {
      title: "Customers",
      icon: <IoIosPeople />,
      location: "/customers",
      submenu: [
        {
          title: "Segments",
          icon: <GrSync />,
          location: "/customers/segments",
        },
      ],
    },
    {
      title: "Products",
      icon: <GrSync />,
      location: "/products",
      submenu: [
        {
          title: "Collections",
          icon: <GrSync />,
          location: "/products/collections",
        },
        {
          title: "Collection List",
          icon: <FaClipboardList />,
          location: "/products/collectionlist",
        },
      ],
    },
    {
      title: "Orders",
      icon: <RiShoppingCart2Line />,
      location: "/orders",
      submenu: [
        {
          title: "Abandoned Checkouts",
          icon: <GrSync />,
          location: "/orders/abandoned",
        },
      ],
    },
    {
      title: "Analytics",
      icon: <IoAppsSharp />,
      location: "/analytics",
      submenu: [
        {
          title: "Reports",
          icon: <GrSync />,
          location: "/analytics/report",
        },
        {
          title: "Live View",
          icon: <GrSync />,
          location: "/analytics/liveview",
        },
      ],
    },
    {
      title:"Settings",
      icon: <IoSettingsOutline/> ,
      location:"settings"
    }
  ];

  return (
    <div
    className={`h-screen bg-gray-700 shadow-md overflow-hidden flex flex-col ${
      isCollapsed ? "w-16" : "w-60"
    } transition-all duration-300`}
    onMouseEnter={() => setIsCollapsed(false)}
    onMouseLeave={() => setIsCollapsed(true)}
  >
    {/* Sidebar Logo Section */}
    <div
  className="flex items-center py-4 bg-gray-700 cursor-pointer"
  onClick={() => navigate("/dashboard")}
>
  <img
    src={isCollapsed ? logo2 : logo}
    alt="Logo"
    className={`transition-all duration-300 ml-2`}
    style={{
      objectFit: "fill",
      width: isCollapsed ? "55px" : "168px", 
      height: "40px", 
    }}
  />
</div>

  
    {/* Navigation Items */}
    <div className="flex flex-col flex-1 items-start py-4">
      {NavigationData.map((item, index) => (
        <div key={index} className="w-full">
          <NavLink
            className={({ isActive }) =>
              `flex items-center gap-3 px-2 py-3 w-full rounded-lg transition-colors duration-200 ${
                isActive
                  ? "bg-orange-600 text-white"
                  : "hover:bg-orange-300 text-white"
              }`
            }
            to={item.location}
          >
            <span
              className={`text-lg ${isCollapsed ? "flex justify-center ml-3" : "text-lg"}`}
              style={{ fontSize: isCollapsed ? "1.2rem" : "1.2rem" }}
            >
              {item.icon}
            </span>
            {!isCollapsed && <p className="text-sm capitalize">{item.title}</p>}
          </NavLink>
          {location.includes(item.location) &&
            item.submenu &&
            item.submenu.length > 0 && (
              <div className="pl-6 mt-2">
                {item.submenu.map((subitem, subIndex) => (
                  <NavLink
                    key={subIndex}
                    className={({ isActive }) =>
                      `flex items-center gap-2 px-2 py-2 rounded-md transition-colors duration-200 ${
                        isActive
                          ? "bg-orange-500 text-white"
                          : "hover:bg-orange-300 text-white"
                      }`
                    }
                    to={subitem.location}
                  >
                    <PiArrowBendDownRightLight className="text-orange-600" />
                    {!isCollapsed && (
                      <span className="text-sm capitalize">{subitem.title}</span>
                    )}
                  </NavLink>
                ))}
              </div>
            )}
        </div>
      ))}
    </div>
  
    {/* Settings Button */}
    {/* <button
      onClick={() => navigate("/settings")}
      className={`flex items-center px-2 py-3 ml-3 gap-2 w-full text-white hover:bg-orange-300 rounded-lg transition-colors duration-200 `}
    >
      <IoSettingsOutline
        className="text-lg"
        style={{ fontSize: isCollapsed ? "1.2rem " : "1.2rem" }}
      />
      {!isCollapsed && <span>Settings</span>}
    </button> */}
  </div>

  );
};

export default Sidebar;
