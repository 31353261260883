import React from 'react'

const Settings = () => {
  return (
    <div className='p-5'>
      Settings
      Settings
    </div>
  )
}

export default Settings
