import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const DashboardLayout = () => {
  return (
    <div className="flex flex-col h-screen">
      {/* Content Area */}
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <Sidebar className="flex-shrink-0" />

        {/* Main Content */}
        <div className="flex-1 overflow-y-auto  bg-gray-100" >
         <Header className="flex-shrink-0" />

          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;