import React from 'react'

const Analytics = () => {
  return (
    <div className='p-6'>
      Analytics
      Analytics
    </div>
  )
}

export default Analytics
