import React from "react";
import { GoBell } from "react-icons/go";

const NotificationBell = () => (
  <button className="bg-zinc-300 p-3 rounded-full text-sm md:text-xl relative">
    <span className="w-2 h-2 rounded-full bg-red-500 absolute right-3 top-3"></span>
    <GoBell />
  </button>
);

export default NotificationBell;
