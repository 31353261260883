import React from "react";
import { useEffect } from "react";
import WOW from "wow.js";
import { FaTwitter, FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";
import logo from "../assets/HomeImages/Q2.png";
import { TfiEmail } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  return (
    <footer
      className="text-white py-10 rounded-tl-[18px] rounded-tr-[18px] lg:rounded-tl-[56px] lg:rounded-tr-[56px] fkex"
      style={{ background: "#3e3e3e" }}
    >
      <div
        className="max-w-7xl mx-auto px-6 md:px-12 lg:px-16 wow fadeInUp"
        data-wow-duration="2s"
      >
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-8"> */}
        <div className="flex justify-between gap-x-8">
          <div className="space-y-4">
            <div
              className="text-2xl font-bold text-gray-800"
              style={{ width: "180px" }}
            >
              <img
                src={logo}
                alt="banner logo"
                className="w-full bg-white-500 p-0"
              />
            </div>
            <div class="flex flex-col lg:text-start text-center">
              <p class="text-lg font-semibold md:font-bold font-CircularRegular text-[#ea580d] mb-1">
                Registered office
              </p>
              {/* <span className=" text-[#ea580d]">Address:</span> */}
              <span className="text-xs md:text-sm">
                WeWork Eldeco Centre, Block A, Shivalik Colony,
                <br /> Malviya Nagar, New Delhi, 110017
              </span>

              {/* <span className=" text-[#ea580d]">Email:</span> */}
              <span className="text-sm my-2 gap-2 flex items-center lg:justify-start justify-center">
                <TfiEmail />
                info@qwikco.ai
              </span>
            </div>
          </div>

          <div className="">
            <div className="">
              <h3 className="font-semibold text-2xl w-full">Company</h3>
            </div>
              <ul className="space-y-2 text-md linkHover">
                <li className="w-full text-end cursor-pointer" onClick={()=> navigate("/") } >About us</li>
                <li className="w-full text-end cursor-pointer" onClick={()=> navigate("/contact") }>Contact Us</li>
                <li className="w-full text-end cursor-pointer" onClick={()=> navigate("/order_tracking") }>Tracking</li>
              </ul>
          </div>

          {/* <div className="space-y-4">
            <h3 className="font-semibold text-2xl">Products</h3>
            <ul className="space-y-2 text-md linkHover">
              <li>Container Leasing</li>
              <li>Container Trading</li>
              <li>Ocean Freight</li>
              <li>Container Control</li>
              <li>Container Tracking</li>
            </ul>
          </div> */}

          {/* <div className="space-y-4">
            <h3 className="font-semibold text-2xl">Join our newsletter</h3>
            <div className="flex items-center border-b border-gray-600 pb-2">
              <input
                type="email"
                placeholder="Enter email address"
                className="bg-transparent flex-1 outline-none font-bold text-white-500 placeholder-white-500 text-md"
              />
              <button className="text-orange-500 ml-2 text-lg">&rarr;</button>
            </div>
            <div className="flex socialIcon space-x-4 lg:pb-0 pb-4 mt-4">
              <a href="#" className="socialIconLink">
                <FaTwitter size={20} />
              </a>
              <a href="#" className="socialIconLink">
                <FaInstagram size={20} />
              </a>
              <a href="#" className="socialIconLink">
                <FaLinkedin size={20} />
              </a>
              <a href="#" className="socialIconLink">
                <FaFacebook size={20} />
              </a>
            </div>

          </div> */}
        </div>
        <div className="text-center lg:mt-12 mt-0 border-t border-t-gray-500 max-w-7xl mx-auto">
          <p
            className="text-md text-white-400 m-0 mt-6"
            style={{ letterSpacing: "1px" }}
          >
            Qwikco All rights reserved © 2024-25
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
