import React, { useState } from "react";

const OrderForm = () => {
  const [formData, setFormData] = useState({
    mobileNumber: "",
    fullName: "",
    email: "",
    address: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
    productName: "",
    quantity: 1,
    unitPrice: 0,
    deadWeight: 0,
    length: 0,
    breadth: 0,
    height: 0,
    paymentMethod: "prepaid",
  });

  const [picupdata, setPicupData] = useState({
    tag: "Home",
    contactPerson: "",
    contactNumber: "",
    email: "",
    alternatePhone: "",
    address: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
  });

  const [openModal,setOpenModal] = useState(false)
  const openModalHandle = () =>{
     setOpenModal(true)
  }

  const closeModalHandle = () =>{
    setOpenModal(false)
  }

  const [errors, setErrors] = useState({});
  const [errorsModal, setErrorsModal] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!picupdata.contactPerson.trim())
      newErrors.contactPerson = "Contact person is required.";
    if (
      !picupdata.contactNumber.trim() ||
      !/^\d{10}$/.test(picupdata.contactNumber)
    )
      newErrors.contactNumber = "Valid 10-digit phone number is required.";
    if (!picupdata.email.trim() || !/\S+@\S+\.\S+/.test(picupdata.email))
      newErrors.email = "Valid email address is required.";
    if (!picupdata.address.trim())
      newErrors.address = "Complete address is required.";
    if (!picupdata.pincode.trim() || !/^\d{6}$/.test(picupdata.pincode))
      newErrors.pincode = "Valid 6-digit pincode is required.";
    if (!picupdata.city.trim()) newErrors.city = "City is required.";
    if (!picupdata.state.trim()) newErrors.state = "State is required.";

    setErrorsModal(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setPicupData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim()) {
        delete newErrors[name];
      } else {
        newErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)
          } is required`;
      }
      return newErrors;
    });
  };

  const handleTagChange = (tag) => {
    setPicupData({ ...picupdata, tag });
  };

  const handleSavePickupInfo = () => {
    if (validate()) {
        console.log("Saved Address Data:", picupdata);
        setPicupData({
            tag: "Home",
            contactPerson: "",
            contactNumber: "",
            email: "",
            alternatePhone: "",
            address: "",
            landmark: "",
            pincode: "",
            city: "",
            state: "",
            country: "India",
          });
  }};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = "Enter a valid 10-digit mobile number.";
    }

    if (!formData.fullName) {
      newErrors.fullName = "Full name is required.";
    }

    if (formData.email && !/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!formData.address) {
      newErrors.address = "Address is required.";
    }

    if (!formData.pincode) {
      newErrors.pincode = "Pincode is required.";
    } else if (!/^\d{6}$/.test(formData.pincode)) {
      newErrors.pincode = "Enter a valid 6-digit pincode.";
    }

    if (!formData.city) {
      newErrors.city = "City is required.";
    }

    if (!formData.state) {
      newErrors.state = "State is required.";
    }

    if (!formData.country) {
      newErrors.country = "Country is required.";
    }

    if (!formData.productName) {
      newErrors.productName = "Product name is required.";
    }

    if (formData.quantity <= 0) {
      newErrors.quantity = "Quantity must be at least 1.";
    }

    if (formData.unitPrice < 0) {
      newErrors.unitPrice = "Unit price cannot be negative.";
    }

    if (formData.deadWeight < 0) {
      newErrors.deadWeight = "Dead weight cannot be negative.";
    }

    if (formData.length < 0) {
      newErrors.length = "Length cannot be negative.";
    }

    if (formData.breadth < 0) {
      newErrors.breadth = "Breadth cannot be negative.";
    }

    if (formData.height < 0) {
      newErrors.height = "Height cannot be negative.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log("Form Data:", formData);
      console.log("Form submitted successfully!");
    } else {
      console.log("Please fix the errors before submitting.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 w-full">
      <div className="flex-grow flex justify-center items-start p-6">
        <div className="w-full bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-lg font-medium mb-4">Pick Up From</h2>
          <p className="text-sm text-gray-700 mb-4">
            Where is the order being sent from?
          </p>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.9 14.32a8 8 0 111.41-1.41l3.85 3.86a1 1 0 01-1.42 1.42l-3.85-3.86zm-4.9-1.32a6 6 0 100-12 6 6 0 000 12z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <input
              type="text"
              placeholder="Search your pick up address here by nickname or phone number"
              className="w-full pl-10 py-2 text-sm text-gray-700 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>

          <p className="text-blue-700 cursor-pointer mt-4 text-[13px]" onClick={()=>openModalHandle()} style={{width:"200px"}}> +Add another pickup address </p>


          {openModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-end z-50 rounded-lg shadow-lg "
          onClick={closeModalHandle}
        >
          <div
            className="bg-white h-full w-[80vw]  p-6 relative shadow-lg animate-slide-in overflow-y-auto  scrollbar-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close Button */}
            <button
              className="absolute top-4 right-6 text-gray-500 hover:text-gray-700"
              onClick={closeModalHandle}
            >
             X
            </button>

            {/* Modal Content */}
            <div className="max-w-full mx-auto bg-white p-0 ">
      <h1 className="text-xl font-semibold mb-3">Add New Pick Up Address</h1>

  <div className="mb-2">
  <label className="block text-sm font-medium mb-2">
    Tag this address as
  </label>
  <div className="flex flex-wrap gap-4">
    {["Home", "Work", "Warehouse", "Other"].map((tag) => (
      <button 
        key={tag}
        onClick={() => handleTagChange(tag)}
        className={`px-4 py-1 rounded-lg border ${
          picupdata.tag === tag ? "bg-blue-500 text-white" : "bg-gray-100"
        }`}
      >
        {tag}
      </button>
    ))}
  </div>
</div>


      <div className="grid grid-cols-2 gap-4 mb-2">
        <div>
          <label className="block text-sm font-medium mb-1">
            Contact Person
          </label>
          <input
            type="text"
            name="contactPerson"
            value={picupdata.contactPerson}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-1 ${errorsModal.contactPerson ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="Name of the person to be contacted"
          />
          {errorsModal.contactPerson && (
            <p className="text-red-500 text-sm">{errorsModal.contactPerson}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Contact Number
          </label>
          <input
            type="text"
            name="contactNumber"
            value={picupdata.contactNumber}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-1 ${errorsModal.contactNumber ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="Enter 10 digit mobile number"
          />
          {errorsModal.contactNumber && (
            <p className="text-red-500 text-sm">{errorsModal.contactNumber}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Email Address
          </label>
          <input
            type="email"
            name="email"
            value={picupdata.email}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-1 ${errorsModal.email ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="i.e abc@gmail.com"
          />
          {errorsModal.email && (
            <p className="text-red-500 text-sm">{errorsModal.email}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Alternate Phone No. (Optional)
          </label>
          <input
            type="text"
            name="alternatePhone"
            value={picupdata.alternatePhone}
            onChange={handleInputChange}
            className="w-full border rounded-lg px-4 py-1 border-gray-300"
            placeholder="Enter 10 digit mobile number"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium mb-1">
            Complete Address
          </label>
          <textarea
            name="address" rows={1}
            value={picupdata.address}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-1 ${errorsModal.address ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="House/Floor No., Building Name or Street, Locality"
          />
          {errorsModal.address && (
            <p className="text-red-500 text-sm">{errorsModal.address}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Landmark</label>
          <input type="text" name="landmark" value={picupdata.landmark}
            onChange={handleInputChange} className="w-full border rounded-lg px-4 py-1 border-gray-300"
            placeholder="Nearby post office, market, hospital, etc."
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Pincode</label>
          <input
            type="text"
            name="pincode"
            value={picupdata.pincode}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-1 ${errorsModal.pincode ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="Add Pincode"
          />
          {errorsModal.pincode && (
            <p className="text-red-500 text-sm">{errorsModal.pincode}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">City</label>
          <input
            type="text"
            name="city"
            value={picupdata.city}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-1 ${errorsModal.city ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="City"
          />
          {errorsModal.city && <p className="text-red-500 text-sm">{errorsModal.city}</p>}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">State</label>
          <input
            type="text"
            name="state"
            value={picupdata.state}
            onChange={handleInputChange}
            className={`w-full border rounded-lg px-4 py-1 ${errorsModal.state ? "border-red-500" : "border-gray-300"
              }`}
            placeholder="State"
          />
          {errorsModal.state && (
            <p className="text-red-500 text-sm">{errorsModal.state}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Country</label>
          <input
            type="text"
            name="country"
            value={picupdata.country}
            onChange={handleInputChange}
            className="w-full border rounded-lg px-4 py-1 border-gray-300"
            readOnly
          />
        </div>
      </div>

      <div className="flex justify-end gap-2 items-center mb-2">
        <button
          type="button"
          onClick={closeModalHandle}
          className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-gray-200 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
          Cancel
        </button>
        <button onClick={handleSavePickupInfo}
          type="button"
          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Save
        </button>
      </div>
    </div>
            
          </div>
        </div>
      )}

          {/* Buyer Details */}
          <div className="mb-6 mt-6">
            <h2 className="text-lg font-medium mb-4">Add Buyer's Details</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Mobile Number
                </label>
                <input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.mobileNumber ? "border-red-500" : ""
                  }`}
                  placeholder="Enter your mobile number"
                />
                {errors.mobileNumber && (
                  <p className="text-red-500 text-sm">{errors.mobileNumber}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.fullName ? "border-red-500" : ""
                  }`}
                  placeholder="Enter your full name"
                />
                {errors.fullName && (
                  <p className="text-red-500 text-sm">{errors.fullName}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Email (Optional)
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.email ? "border-red-500" : ""
                  }`}
                  placeholder="Enter your email"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="col-span-2">
                <label className="block text-gray-700 text-sm mb-2">
                  Complete Address
                </label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.address ? "border-red-500" : ""
                  }`}
                  placeholder="House/Floor No., Building Name or Street, Locality"
                />
                {errors.address && (
                  <p className="text-red-500 text-sm">{errors.address}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Landmark (Optional)
                </label>
                <input
                  type="text"
                  name="landmark"
                  value={formData.landmark}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg"
                  placeholder="Nearby post office, market, hospital"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Pincode
                </label>
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.pincode ? "border-red-500" : ""
                  }`}
                  placeholder="Enter Buyer's Pincode"
                />
                {errors.pincode && (
                  <p className="text-red-500 text-sm">{errors.pincode}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.city ? "border-red-500" : ""
                  }`}
                  placeholder="Enter your city"
                />
                {errors.city && (
                  <p className="text-red-500 text-sm">{errors.city}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.state ? "border-red-500" : ""
                  }`}
                  placeholder="Enter your state"
                />
                {errors.state && (
                  <p className="text-red-500 text-sm">{errors.state}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.country ? "border-red-500" : ""
                  }`}
                  placeholder="Enter your country"
                  disabled
                />
                {errors.country && (
                  <p className="text-red-500 text-sm">{errors.country}</p>
                )}
              </div>
            </div>
          </div>

          {/* Order Details */}
          <div className="mb-6">
            <h2 className="text-lg font-medium mb-4">Order Details</h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Product Name
                </label>
                <input
                  type="text"
                  name="productName"
                  value={formData.productName}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.productName ? "border-red-500" : ""
                  }`}
                  placeholder="Enter product name"
                />
                {errors.productName && (
                  <p className="text-red-500 text-sm">{errors.productName}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Quantity
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="quantity"
                  value={formData.quantity}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.quantity ? "border-red-500" : ""
                  }`}
                  min="1"
                />
                {errors.quantity && (
                  <p className="text-red-500 text-sm">{errors.quantity}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Unit Price
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="unitPrice"
                  value={formData.unitPrice}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.unitPrice ? "border-red-500" : ""
                  }`}
                  min="0"
                />
                {errors.unitPrice && (
                  <p className="text-red-500 text-sm">{errors.unitPrice}</p>
                )}
              </div>
            </div>
          </div>

          {/* Package Details */}
          <div className="mb-6">
            <h2 className="text-lg font-medium mb-4">Package Details</h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Dead Weight (Kg)
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="deadWeight"
                  value={formData.deadWeight}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.deadWeight ? "border-red-500" : ""
                  }`}
                  min="0"
                />
                {errors.deadWeight && (
                  <p className="text-red-500 text-sm">{errors.deadWeight}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Length (cm)
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.length ? "border-red-500" : ""
                  }`}
                  min="0"
                />
                {errors.length && (
                  <p className="text-red-500 text-sm">{errors.length}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Breadth (cm)
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="breadth"
                  value={formData.breadth}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.breadth ? "border-red-500" : ""
                  }`}
                  min="0"
                />
                {errors.breadth && (
                  <p className="text-red-500 text-sm">{errors.breadth}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Height (cm)
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg ${
                    errors.height ? "border-red-500" : ""
                  }`}
                  min="0"
                />
                {errors.height && (
                  <p className="text-red-500 text-sm">{errors.height}</p>
                )}
              </div>
            </div>
          </div>

          {/* Payment Details */}
          <div className="mb-6">
            <h2 className="text-lg font-medium mb-4">Payment Details</h2>
            <div>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="Prepaid"
                  checked={formData.paymentMethod === "Prepaid"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Prepaid
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              onClick={handleSubmit}
              className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg"
            >
              Select Courier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderForm;
