import React, { useEffect, useState } from "react";
import calculator from "../assets/HomeImages/calculatorImg.webp";
import ImmediateComponent from "./Immidate";

const Services = () => {
  const [activeTab, setActiveTab] = useState("Domestic");
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [locationData, setLocationData] = useState(null);
  const [formData, setFormData] = useState({
    pickupPincode: "",
    deliveryPincode: "",
    weight: 0.5,
    length: "",
    width: "",
    height: "",
    paymentMode: "Prepaid",
    shipmentValue: 2000,
  });

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try { 
            const response = await fetch(
              `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=8ae72f6ff5a24de495f6efa19c32b641`
            );
            const data = await response.json();

            setLocationData(data.results[0]);
            console.log("Location data:", data.results[0]); 
          } catch (error) {
            console.error("Error fetching location data:", error);
          }

          setIsPopupVisible(false); 
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          alert("Failed to get location. Please allow location access.");
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };


  const [errors, setErrors] = useState({});

  const validate = () => {
    const validationErrors = {};
    if (!/^\d{6}$/.test(formData.pickupPincode)) {
      validationErrors.pickupPincode =
        "Pickup area pincode must be a 6-digit number.";
    }
    if (!/^\d{6}$/.test(formData.deliveryPincode)) {
      validationErrors.deliveryPincode =
        "Delivery area pincode must be a 6-digit number.";
    }
    if (formData.weight <= 0) {
      validationErrors.weight = "Weight must be greater than 0.";
    }
    if (formData.length && formData.length <= 0) {
      validationErrors.length = "Length must be a positive number.";
    }
    if (formData.width && formData.width <= 0) {
      validationErrors.width = "Width must be a positive number.";
    }
    if (formData.height && formData.height <= 0) {
      validationErrors.height = "Height must be a positive number.";
    }
    if (formData.shipmentValue < 0) {
      validationErrors.shipmentValue = "Shipment value cannot be negative.";
    }
    return validationErrors;
  };

  const calculateCharges = () => {
    const weightCost = parseFloat(formData.weight) * 10 || 0;
    const length = parseFloat(formData.length) || 0;
    const width = parseFloat(formData.width) || 0;
    const height = parseFloat(formData.height) || 0;
    const volumeCost = (length * width * height * 0.5) || 0;
    const shipmentValueCost = (parseFloat(formData.shipmentValue) / 100) * 5 || 0;

    let baseCost = 50;

    const remoteSurcharge =
      formData.pickupPincode.startsWith("1") || formData.deliveryPincode.startsWith("1") ||
      formData.pickupPincode.startsWith("2") || formData.deliveryPincode.startsWith("2")
        ? 20
        : 0;

    const totalCost = baseCost + weightCost + volumeCost + shipmentValueCost + remoteSurcharge;

    return totalCost.toFixed(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      const calculatedValue = calculateCharges();
      console.log("Calculated Value:", calculatedValue);
      console.log("Form submitted successfully:", formData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReset = () => {
    setFormData({
      pickupPincode: "",
      deliveryPincode: "",
      weight: 0.5,
      dimensions: { length: "", width: "", height: "" },
      paymentMode: "Prepaid",
      shipmentValue: 2000,
    });
  };

  <button
    className="px-6 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
    type="button"
    onClick={handleReset}
  >
    Reset
  </button>;

  useEffect(() => {
    const WOW = require("wow.js");
    new WOW().init();
  }, []);

  return (
    <>    
       {/* Show popup if visible */}
       {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">
              Allow Location Access
            </h2>
            <p className="text-gray-600 mb-6">
              We need your location to provide personalized content.
            </p>
            <div className="flex justify-end gap-3">
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                onClick={() => setIsPopupVisible(false)}
              >
                No, Thanks
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleGetLocation}
              >
                Yes, Allow
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Display location data if available */}
      {locationData && (
        <div className="p-4">
          <h3 className="text-lg font-semibold">Your Location:</h3>
          <p className="text-gray-700">City: {locationData.components.city}</p>
          <p className="text-gray-700">Country: {locationData.components.country}</p>
        </div>
      )}
 
    <section className="lg:px-12 px-4">
      <div
        className="bg-white border shadow-lg w-full max-w-12xl lg:my-12 my-8 mx-auto"
        style={{ borderRadius: "20px" }}
      >
        {/* Tabs Section */}
        <div className="flex border-b">
          <button
            className={`px-6 py-2 font-semibold ${
              activeTab === "Domestic"
                ? "text-orange-600 border-b-2 border-orange-600"
                : "text-gray-600 hover:text-orange-600 hover:border-b-2 "
            }`}
            onClick={() => setActiveTab("Domestic")}
          >
            Domestic
          </button>
          <button
            className={`px-6 py-2 font-semibold ${
              activeTab === "Immediate"
                ? "text-orange-600 border-b-2 border-orange-600"
                : "text-gray-600 hover:text-orange-600 hover:border-b-2 "
            }`}
            onClick={() => setActiveTab("Immediate")}
          >
            Immediate
          </button>
        </div>

        {activeTab === "Domestic" && (
          <div className="flex flex-wrap md:flex-nowrap gap-8 items-center">
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4 md:p-8 w-full md:w-[70%]"
            >
              <div>
                <label className="block text-sm font-bold text-gray-700 mb-1">
                  Pick-up Area Pincode
                  <span className="text-red-500 font-bold">*</span>
                </label>
                <input
                  type="text"
                  name="pickupPincode"
                  value={formData.pickupPincode}
                  onChange={handleInputChange}
                  placeholder="Enter 6 digit Pickup Area Pincode"
                  className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
                />
                {errors.pickupPincode && (
                  <p className="text-red-500 text-xs">{errors.pickupPincode}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700 mb-1">
                  Delivery Area Pincode
                  <span className="text-red-500 font-bold">*</span>
                </label>
                <input
                  type="text"
                  name="deliveryPincode"
                  value={formData.deliveryPincode}
                  onChange={handleInputChange}
                  placeholder="Enter 6 digit Delivery Area Pincode"
                  className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
                />
                {errors.deliveryPincode && (
                  <p className="text-red-500 text-xs">
                    {errors.deliveryPincode}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700 mb-1">
                  Weight<span className="text-red-500 font-bold">*</span>
                </label>
                <div className="flex">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="weight"
                    value={formData.weight}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border outline-none rounded-l-lg focus:ring-2 focus:ring-orange-500"
                  />
                  <span className="px-4 py-2 bg-gray-100 border rounded-r-lg">
                    KG
                  </span>
                </div>
                {errors.weight && (
                  <p className="text-red-500 text-xs">{errors.weight}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700 mb-1">
                  Dimensions (Optional)
                </label>
                <div className="flex gap-2">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="length"
                    value={formData.length}
                    onChange={handleInputChange}
                    placeholder="Length"
                    className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
                  />
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="width"
                    value={formData.width}
                    onChange={handleInputChange}
                    placeholder="Width"
                    className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
                  />
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="height"
                    value={formData.height}
                    onChange={handleInputChange}
                    placeholder="Height"
                    className="w-full px-4 py-2 border outline-none rounded-lg focus:ring-2 focus:ring-orange-500"
                  />
                </div>
                {errors.length && (
                  <p className="text-red-500 text-xs">{errors.length}</p>
                )}
                {errors.width && (
                  <p className="text-red-500 text-xs">{errors.width}</p>
                )}
                {errors.height && (
                  <p className="text-red-500 text-xs">{errors.height}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700 mb-1">
                  Payment Mode<span className="text-red-500 font-bold">*</span>
                </label>
                <div className="flex gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="paymentMode"
                      value="Prepaid"
                      checked={formData.paymentMode === "Prepaid"}
                      onChange={handleInputChange}
                      className="focus:ring-purple-500"
                    />
                    Prepaid
                  </label>
                  {/* <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="paymentMode"
                  value="COD"
                  checked={formData.paymentMode === "COD"}
                  onChange={handleInputChange}
                  className="focus:ring-purple-500"
                />
                Cash on Delivery
              </label> */}
                </div>
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700 mb-1">
                  Shipment Value
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="shipmentValue"
                  value={formData.shipmentValue}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border outline-none Shipment Value rounded-lg focus:ring-2 focus:ring-orange-500"
                />
                {errors.shipmentValue && (
                  <p className="text-red-500 text-xs">{errors.shipmentValue}</p>
                )}
              </div>
              <div className="mt-2 flex gap-4">
                <button
                  type="submit"
                  className="px-6 py-2 bg-orange-500 text-white rounded-lg"
                >
                  Calculate
                </button>
                <button
                  type="button"
                  className="px-6 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
            <div
              className="hidden md:flex w-full md:w-[30%] justify-end"
              style={{ display: "flex" }}
            >
              <img
                src={calculator}
                alt="Calculator"
                className="w-64 md:block hidden"
              />
            </div>
          </div>
        )}

        {activeTab === "Immediate" && <ImmediateComponent />}
      </div>
    </section>
    </>
  );
};

export default Services;
