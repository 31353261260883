import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
// import BeatLoader from "react-spinners/BeatLoader";

function PackageDetails({ handlePrevious, handleNext }) {
  const [decodedData, setDecodedData] = useState({
    orderinfo: null,
    pickupinfo: null,
    buyerInfo: null,
  });
  
  const [errors, setErrors] = useState({});

  const [wAndD, setwAndD] = useState({
    weight: "",
    weightUnit: "",
    length: "",
    width: "",
    height: "",
    dimensionUnit: "",
    delivery_type: "",
  });

  function WandChangeHandler(e) {
    const { name, value } = e.target;
    // setwAndD({ ...wAndD, [e.target.name]: e.target.value });
    setwAndD({ ...wAndD, [name]: value });
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value.trim() !== "") {
        delete updatedErrors[name];
      } else {
        updatedErrors[name] = `Please enter a valid ${name}.`;
      }
      return updatedErrors;
    });
  }

  // const validateInputs = () => {
  //   const errors = {};
  //   if (!wAndD.weight || parseFloat(wAndD.weight) <= 0) {
  //     errors.weight = "Weight must be greater than 0";
  //   }
  //   if (!wAndD.length || parseFloat(wAndD.length) < 0.5) {
  //     errors.length = "Length must be greater than or equal to 0.5";
  //   }
  //   if (!wAndD.width || parseFloat(wAndD.width) < 0.5) {
  //     errors.width = "Width must be greater than or equal to 0.5";
  //   }
  //   if (!wAndD.height || parseFloat(wAndD.height) < 0.5) {
  //     errors.height = "Height must be greater than or equal to 0.5";
  //   }
  //   if (!wAndD.delivery_type) {
  //     errors.delivery_type = "Delivery type is required";
  //   }
  //   return errors;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!wAndD.weight || isNaN(wAndD.weight) || wAndD.weight <= 0) {
      validationErrors.weight = "Please enter a valid weight.";
    }

    if (!wAndD.weightUnit) {
      validationErrors.weightUnit = "Please select a weight unit.";
    }

    if (!wAndD.dimensionUnit) {
      validationErrors.dimensionUnit = "Please select a dimension unit.";
    }

    ["length", "width", "height"].forEach((dim) => {
      if (!wAndD[dim] || isNaN(wAndD[dim]) || wAndD[dim] <= 0) {
        validationErrors[dim] = `Please enter a valid ${dim}.`;
      }
    });

    if (!wAndD.delivery_type) {
      validationErrors.delivery_type = "Please select a delivery type.";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted successfully:", wAndD);
      const data = {
        orderinfo: decodedData.orderinfo,
        pickupinfo: decodedData.pickupinfo,
        buyerInfo: decodedData.buyerInfo,
      };
     
     if(!data.orderinfo || !data.pickupinfo || !data.buyerInfo){
       alert("Please fill all the previous details")
       return
     }else{
      const payload = {
        mobile_no: data.buyerInfo.contact_no,
        full_name: data.buyerInfo.name,
        email_id: data.buyerInfo.email,
        pickup_contact_person: data.pickupinfo.contactPerson,
        pickup_contact_number: data.pickupinfo.contactNumber,
        pickup_email: data.pickupinfo.email,
        pickup_alternate_phone: data.pickupinfo.alternatePhone,
        pickup_complete_address: data.pickupinfo.address,
        pickup_landmark: data.pickupinfo.landmark,
        pickup_pincode: data.pickupinfo.pincode,
        gps: `${data.buyerInfo.latitude},${data.buyerInfo.longitude}`,
        pickup_city: data.pickupinfo.city,
        pickup_state: data.pickupinfo.state,
        pickup_country: data.pickupinfo.country,
        delivery_contact_person: data.buyerInfo.delivery_contact_person,
        delivery_contact_number: data.buyerInfo.delivery_contact_number,
        delivery_email: data.buyerInfo.delivery_email,
        delivery_alternate_phone: data.buyerInfo.delivery_alternate_phone,
        delivery_complete_address: data.buyerInfo.delivery_complete_address,
        delivery_gps: `${data.buyerInfo.latitude},${data.buyerInfo.longitude}`,
        delivery_landmark: data.buyerInfo.delivery_landmark,
        delivery_pincode: data.buyerInfo.delivery_pincode,
        delivery_city: data.buyerInfo.delivery_city,
        delivery_state: data.buyerInfo.delivery_state,
        delivery_country: data.buyerInfo.delivery_country,
        billing_mobile_no: data.buyerInfo.billing_mobile_no,
        billing_full_name: data.buyerInfo.billing_full_name,
        billing_email: data.buyerInfo.billing_email,
        billing_complete_address: data.buyerInfo.billing_complete_address,
        billing_landmark: data.buyerInfo.billing_landmark,
        billing_pincode: data.buyerInfo.billing_pincode,
        billing_city: data.buyerInfo.billing_city,
        billing_state: data.buyerInfo.billing_state,
        billing_country: data.buyerInfo.delivery_country,
  
        product_order_id: data.orderinfo.prodictOrderid,
        order_date: data.orderinfo.orderDate,
        product_name: data.orderinfo.products[0].name,
        unit_price: Number(data.orderinfo.products[0].unitPrice),
        quantity: Number(data.orderinfo.products[0].quantity),
        product_category: data.orderinfo.products[0].category,
        hsn_code: data.orderinfo.hsnCode,
        sku: data.orderinfo.sku,
        tax_rate: Number(data.orderinfo.taxRate),
        product_discount: Number(data.orderinfo.productDiscount),
        payment_type:  data.orderinfo.paymentMethod.charAt(0).toLowerCase() + data.orderinfo.paymentMethod.slice(1),
        shipping_charges: Number(data.orderinfo.shippingCharges),
        gift_wrap: Number(data.orderinfo.giftWrap),
        transaction_fee: Number(data.orderinfo.transactionFee),
        total_discounts: Number(data.orderinfo.discounts),
     
        unit: wAndD.weightUnit,
        weight: Number(wAndD.weight),
        dimensions: `${wAndD.length}x${wAndD.height}x${wAndD.width}`,
        length: Number(wAndD.length),
        width: Number(wAndD.width),
        height: Number(wAndD.height),
        delivery_type: wAndD.delivery_type,
      };

      console.log("paylaod to sent---->",payload)
  
      try {
        const response = await fetch(
          "https://preprod.xpressbaazaar.com/buyer/logistics/create/order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer your-auth-token",
            },
            body: JSON.stringify(payload),
          }
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log("Order successfully sent:", data);
        alert("Order successfully sent")
         setwAndD({
          weight: "",
          weightUnit: "",
          length: "",
          width: "",
          height: "",
          dimensionUnit: "",
          delivery_type: "",
        });
        localStorage.clear()
      } catch (error) {
        console.error("Error sending order details:", error.message);
      }
     }
    }
  };

  useEffect(() => {
    try {
      const orderinfo = localStorage.getItem("orderinfo");
      const pickupinfo = localStorage.getItem("pickupinfo");
      const savedBuyerInfo = localStorage.getItem("buyerInfo");

      let newDecodedData = {};

      if (orderinfo) {
        const decodedOrderInfo = CryptoJS.enc.Base64.parse(orderinfo).toString(
          CryptoJS.enc.Utf8
        );
        newDecodedData.orderinfo = JSON.parse(decodedOrderInfo);
      }

      if (pickupinfo) {
        const decodedPickupInfo = CryptoJS.enc.Base64.parse(
          pickupinfo
        ).toString(CryptoJS.enc.Utf8);
        newDecodedData.pickupinfo = JSON.parse(decodedPickupInfo);
      }

      if (savedBuyerInfo) {
        const decodedBuyerInfo = CryptoJS.enc.Base64.parse(
          savedBuyerInfo
        ).toString(CryptoJS.enc.Utf8);
        newDecodedData.buyerInfo = JSON.parse(decodedBuyerInfo);
      }

      // Update state with decoded data
      setDecodedData(newDecodedData);
      console.log("Decoded data saved to state:", newDecodedData);
    } catch (error) {
      console.error("Error decoding data from localStorage:", error.message);
    }
  }, []);

  return (
    <div className="max-w-full mx-auto p-6 rounded-md">
      <h1 className="text-2xl font-bold mb-4">Package Details</h1>
      <div className="pb-4">
        <div className=" mx-auto bg-white shadow-lg rounded-lg border border-gray-200">
          <div className="p-6 space-y-8">
            {/* Dead Weight Section */}
            <div>
  <label className="block text-sm font-medium text-gray-700 mb-2">
    Dead Weight:
  </label>
  <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
    <input
      type="number"
      name="weight"
      onChange={(e) => WandChangeHandler(e)}
      placeholder="0.00"
      className={`w-full sm:flex-1 px-3 py-2 bg-white border ${
        errors.weight ? "border-red-500" : "border-gray-300"
      } rounded-md focus:outline-none focus:ring-2 ${
        errors.weight ? "focus:ring-red-400" : "focus:ring-blue-400"
      }`}
    />
    {errors.weight && (
      <span className="text-red-500 text-sm sm:mt-0">{errors.weight}</span>
    )}

    <select
      className={`w-full sm:w-auto px-3 py-2 bg-white border ${
        errors.weightUnit ? "border-red-500" : "border-gray-300"
      } rounded-md focus:outline-none focus:ring-2 ${
        errors.weightUnit ? "focus:ring-red-400" : "focus:ring-blue-400"
      }`}
      value={wAndD?.weightUnit}
      name="weightUnit"
      onChange={(e) => WandChangeHandler(e)}
    >
      <option value="" disabled>Select Unit</option>
      <option value="gram">Gram</option>
      <option value="kilogram">Kilogram</option>
    </select>
    {errors.weightUnit && (
      <span className="text-red-500 text-sm">{errors.weightUnit}</span>
    )}
  </div>
</div>

            {/* Volumetric Weight Section */}
            <div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Dimensions:
                </label>
                <div className="flex items-center gap-4 mb-4">
                  <select
                    name="dimensionUnit"
                    // className="w-1/2 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    className={`w-1/2 px-3 py-2 bg-white border ${
                      errors.dimensionUnit
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-2 ${
                      errors.dimensionUnit
                        ? "focus:ring-red-400"
                        : "focus:ring-blue-400"
                    }`}
                    value={wAndD?.dimensionUnit}                                                                                                                                                                      
                    onChange={(e) => WandChangeHandler(e)}
                  >
                    <option value="" disabled>Select Unit</option>
                    <option value="meter">Meter</option>
                    <option value="centimeter">Centimeter</option>
                    <option value="inch">Inch</option>
                  </select>
                  {errors.dimensionUnit && (
                    <span className="text-red-500 text-sm">
                      {errors.dimensionUnit}
                    </span>
                  )}
                </div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Volumetric Weight:
                </label>
                <div className="grid mb-2 grid-cols-3 gap-4">
                  {["length", "width", "height"].map((dim) => (
                    <div key={dim}>
                      <label className="block text-sm font-medium text-gray-500">
                        {dim.charAt(0).toUpperCase() + dim.slice(1)}:
                      </label>
                      <input
                        type="number"
                        name={dim}
                        value={wAndD[dim] || ""}
                        onChange={WandChangeHandler}
                        placeholder="0.00"
                        // className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        className={`w-full px-3 py-2 bg-white border ${
                          errors[dim] ? "border-red-500" : "border-gray-300"
                        } rounded-md focus:outline-none focus:ring-2 ${
                          errors[dim] ? "focus:ring-red-400" : "focus:ring-blue-400"
                        }`}
                      />
                       {errors[dim] && (
                        <span className="text-red-500 text-sm">
                          {errors[dim]}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Delivery Type:
                </label>
                <select
                  name="delivery_type"
                  // className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  className={`w-full px-3 py-2 bg-white border ${
                    errors.delivery_type ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 ${
                    errors.delivery_type
                      ? "focus:ring-red-400"
                      : "focus:ring-blue-400"
                  }`}
                  value={wAndD?.delivery_type}
                  onChange={(e) => WandChangeHandler(e)}
                >
                  <option value="">Select Delivery Type</option>
                  <option value="Express Delivery">Express Delivery</option>
                  <option value="Standard Delivery">Standard Delivery</option>
                  <option value="Immediate Delivery">Immediate Delivery</option>
                  <option value="Same Day Delivery">Same Day Delivery</option>
                  <option value="Next Day Delivery">Next Day Delivery</option>
                </select>
                {errors.delivery_type && (
                  <span className="text-red-500 text-sm">
                    {errors.delivery_type}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2 items-center mt-4">
        <button
          type="button"
          onClick={handlePrevious}
          className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-gray-200 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
          previous
        </button>
        <button
          onClick={handleSubmit}
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default PackageDetails;
