import React, { useState } from "react";
import "./layout.css";
import { RxHamburgerMenu } from "react-icons/rx";
import Greeting from "./headerhelper/Greeting.js";
import SearchBar from "./headerhelper/Searchbar.js";
import NotificationBell from "./headerhelper/Notificationbell.js";
import { IoPowerSharp } from "react-icons/io5";

const Header = ({ className }) => {
  const [openProfile, setOpenProfile] = useState(false);

  // Static user data
  const userData = {
    name: "ENS Enterprises",
    role: "Admin",
    username: "johndoe",
  };

  const logOutHandler = () => {
    alert("Logged out!");
  };

  return (
    <div
      className={`bg-gray-700 shadow-lg px-6 py-3 flex items-center justify-between  text-white ${className}`}
      style={{borderTopRightRadius: "10px",borderBottomRightRadius:"10px"}}
      >
      {/* Greeting Section */}
      <div className="flex items-center gap-4">
        {/* <div
          className="text-2xl cursor-pointer block md:hidden"
          title="Toggle Menu"
        >
          <RxHamburgerMenu />
        </div> */}
        <Greeting userData={userData} />
      </div>

      {/* Right Section */}
      <div className="flex items-center gap-6 relative">
        <SearchBar />
        <NotificationBell />
        <div
          className="bg-white border-2 border-blue-500 text-blue-500 text-lg flex justify-center items-center rounded-full w-10 h-10 uppercase lg:w-12 lg:h-12 overflow-hidden cursor-pointer hover:shadow-lg hover:bg-blue-100"
          onClick={() => setOpenProfile(!openProfile)}
          title="Open Profile"
        >
          {userData?.username[0]}
        </div>

        {openProfile && (
          <div className="absolute shadow-lg right-0 top-[120%] bg-white text-gray-800 z-20 p-4 rounded-lg w-48">
            <div className="font-semibold text-center text-gray-900 border-b pb-2 mb-2">
              {userData.name}
            </div>
            <ul className="space-y-2">
              <li className="text-sm hover:bg-gray-100 p-2 rounded-md cursor-pointer">
                Profile
              </li>
              <li className="text-sm hover:bg-gray-100 p-2 rounded-md cursor-pointer">
                Settings
              </li>
              <li
                className="text-sm text-red-500 hover:bg-red-100 p-2 rounded-md flex items-center gap-2 cursor-pointer"
                onClick={logOutHandler}
              >
                <IoPowerSharp className="text-red-500" />
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
