import React, { useState } from "react";
// import axios from "axios"; 

const SearchBar = () => {
  const [query, setQuery] = useState("");

  const handleSearchChange = async (event) => {
    console.log(event.target.value)
    // const value = event.target.value;
    // setQuery(value);

    // if (value.trim().length >= 3) {
    //   try {
    //     // Make API call when input length is greater than or equal to 3 characters
    //     const response = await axios.get(`https://api.example.com/search?q=${value}`);
    //     console.log(response.data); // Handle your data here
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  return (
    <input
      type="text"
      value={query}
      onChange={handleSearchChange}
      placeholder="Search..."
      className="px-4 py-2 rounded-full text-black w-40 md:w-60"
    />
  );
};

export default SearchBar;
