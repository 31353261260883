import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal as AntModal, Input, Form, message, Select, Button } from "antd";

const Orders = () => {
  const { Option } = Select;
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("New");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [form] = Form.useForm();
  const [data, setData] = useState([
    { id: 1, orderDetails: "Order #1", customer: "John Doe", product: "Laptop", payment: "$1200", status: "Pending" },
    { id: 2, orderDetails: "Order #2", customer: "Jane Smith", product: "Phone", payment: "$800", status: "Shipped" },
    { id: 3, orderDetails: "Order #3", customer: "Alice Brown", product: "Tablet", payment: "$600", status: "Delivered" },
    { id: 4, orderDetails: "Order #4", customer: "David Clark", product: "Headphones", payment: "$200", status: "Pending" },
    { id: 5, orderDetails: "Order #5", customer: "Emily Davis", product: "Smartwatch", payment: "$400", status: "Shipped" },
    { id: 6, orderDetails: "Order #6", customer: "Chris Lee", product: "Camera", payment: "$900", status: "Delivered" },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Pagination Logic
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const showModal = (id) => {
    setSelectedRowId(id);
    setIsModalVisible(true);
    form.resetFields(); 
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Form Values:", values, "Row ID:", selectedRowId);
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };
  

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Header */}
      <header className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">Orders</h1>
        <button className="bg-orange-600 text-white px-4 py-2 rounded-lg" onClick={()=>navigate("/add_order")}>+ Add Order</button>
      </header>

      {/* Tabs */}
      <div className="flex space-x-8 mt-6 border-b">
        {["New", "Ready To Ship", "Pickups & Manifests", "In Transit", "Delivered", "RTO", "All"].map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabChange(tab)}
            className={`pb-2 ${
              activeTab === tab ? "border-b-2 border-blue-600 text-blue-600" : "text-gray-600"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Content Based on Active Tab */}
      <div className="mt-6">
        {activeTab === "New" && (
          <div className="bg-white shadow rounded-lg overflow-auto">
            <table className="w-full">
              <thead className="bg-gray-200">
                <tr>
                  <th className="text-left px-4 py-2">Order Details</th>
                  <th className="text-left px-4 py-2">Customer</th>
                  <th className="text-left px-4 py-2">Product</th>
                  <th className="text-left px-4 py-2">Payment</th>
                  <th className="text-left px-4 py-2">Status</th>
                  <th className="text-left px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((row) => (
                  <tr key={row.id} className="border-t">
                    <td className="px-4 py-2">{row.orderDetails}</td>
                    <td className="px-4 py-2">{row.customer}</td>
                    <td className="px-4 py-2">{row.product}</td>
                    <td className="px-4 py-2">{row.payment}</td>
                    <td className="px-4 py-2">{row.status}</td>
                    <td className="px-4 py-2">
                    <Button type="primary" onClick={() => showModal(row.id)}>
                      View
                    </Button>
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTab === "Ready To Ship" && (
          <div className="bg-white shadow rounded-lg p-6 text-center text-gray-800">
            <h2 className="text-lg font-semibold">Shipments coming soon...</h2>
          </div>
        )}

        {activeTab === "Pickups & Manifests" && (
          <div className="bg-white shadow rounded-lg p-6 text-center text-gray-800">
            <h2 className="text-lg font-semibold">Pickups coming soon...</h2>
          </div>
        )}

        {activeTab === "In Transit" && (
          <div className="bg-white shadow rounded-lg p-6 text-center text-gray-800">
            <h2 className="text-lg font-semibold">In Transit coming soon...</h2>
          </div>
        )}

        {activeTab === "Delivered" && (
          <div className="bg-white shadow rounded-lg p-6 text-center text-gray-800">
            <h2 className="text-lg font-semibold">Delivered orders coming soon...</h2>
          </div>
        )}

        {activeTab === "RTO" && (
          <div className="bg-white shadow rounded-lg p-6 text-center text-gray-800">
            <h2 className="text-lg font-semibold">RTO coming soon...</h2>
          </div>
        )}

        {activeTab === "All" && (
          <div className="bg-white shadow rounded-lg p-6 text-center text-gray-800">
            <h2 className="text-lg font-semibold">All orders coming soon...</h2>
          </div>
        )}
      </div>

      {/* Pagination for New Tab */}
      {activeTab === "New" && (
        <div className="flex justify-between items-center p-4 bg-white shadow rounded-lg mt-4">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            className="bg-gray-200 px-4 py-2 rounded-lg"
          >
            Prev
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            className="bg-gray-200 px-4 py-2 rounded-lg"
          >
            Next
          </button>
        </div>
      )}
       <AntModal
        title="Weight & Dimensions"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleFormSubmit}
        okText="Submit"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="weight"
            label="Total Weight of Products"
            rules={[{ required: true, message: "Please enter the weight!" }]}
          >
            <Input placeholder="Enter weight" />
          </Form.Item>
          <Form.Item
            name="dimensionUnit"
            label="Dimensions"
            rules={[{ required: true, message: "Please select a dimension unit!" }]}
          >
            <Select placeholder="Select unit">
              <Option value="cm">Centimeter</Option>
              <Option value="inch">Inch</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="length"
            label="Length"
            rules={[{ required: true, message: "Please enter the length!" }]}
          >
            <Input placeholder="Enter length" />
          </Form.Item>
          <Form.Item
            name="width"
            label="Width"
            rules={[{ required: true, message: "Please enter the width!" }]}
          >
            <Input placeholder="Enter width" />
          </Form.Item>
          <Form.Item
            name="height"
            label="Height"
            rules={[{ required: true, message: "Please enter the height!" }]}
          >
            <Input placeholder="Enter height" />
          </Form.Item>
          <Form.Item
            name="deliveryType"
            label="Select Delivery Type"
            rules={[{ required: true, message: "Please select a delivery type!" }]}
          >
            <Select placeholder="Select delivery type">
              <Option value="standard">Standard Delivery</Option>
              <Option value="express">Express Delivery</Option>
            </Select>
          </Form.Item>
        </Form>
      </AntModal>   
    </div>
    
  );
};

export default Orders;
