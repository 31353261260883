/// src/utils/apiService.js
import axios from "axios";
import config from "../config";

const apiCall = async (method, endpoint, data = {}, headers = {}) => {
  const url = `${config.baseUrl}`;
  const defaultHeaders = {
    "Content-Type": "application/json",
    ...headers,
  };

  try {
    const response = await axios({
      method,
      url,
      data,
      headers: defaultHeaders,
    });
    return response.data;
  } catch (error) {
    console.error("API Error:", error.response?.data || error.message);
    throw error.response?.data || error.message;
  }
};

export default apiCall;

