import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { IoListCircleSharp } from "react-icons/io5";
import { MdPlaylistAddCircle } from "react-icons/md";
import Basic from "./buyerInfo";
import CompanyDetails from "./pickupInfo";
import UserRoles from "./orderDetails";
import StepContent from "./stepContent";
import Packagedetail from "./PackageDetails"
import { LuPackagePlus } from "react-icons/lu";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import OrderForm from "../../pages/Quickshipment";

const steps = [
  { stepNum: 1, component: <Basic /> },
  { stepNum: 2, component: <CompanyDetails /> },
  { stepNum: 3, component: <UserRoles /> },
  { stepNum: 4, component: <Packagedetail /> },
];


const AddOrder = () => {
  const [isDataSaved, setIsDataSaved] = useState({});
  const [toggleState, setToggleState] = useState(1);
  const [toggleTab , setToggleTab] = useState(1)

  const handlePrevious = () => {
    const previousTab = toggleState > 1 ? toggleState - 1 : toggleState;
    setToggleState(previousTab);
  };

  const handleNextAndSave = () => {
    saveDataForComponent();
    const nextTab = toggleState < 4 ? toggleState + 1 : toggleState;
    setToggleState(nextTab);
  };

  const saveDataForComponent = async () => {
    const currentStep = steps.find((step) => step.stepNum === toggleState);
    if (currentStep && currentStep.component) {
      if (typeof currentStep.component.props.saveData === "function") {
        await currentStep.component.props.saveData();
      }
      setIsDataSaved((prevData) => ({ ...prevData, [toggleState]: true }));
    }
  };

  const renderCurrentComponent = () => {
    const currentStep = steps.find((step) => step.stepNum === toggleState);
    if (currentStep) {
      return (
        <StepContent
          stepNum={toggleState}
          saveData={saveDataForComponent}
          handlePrevious={handlePrevious}
          handleNext={handleNextAndSave}
          isSaveDisabled={!isDataSaved[toggleState]}
          toggleState={toggleState}
        >
          {React.cloneElement(currentStep.component, {
            saveData: saveDataForComponent,
            handlePrevious: handlePrevious,
            handleNext: handleNextAndSave,
            isSaveDisabled: !isDataSaved[toggleState],
            toggleState: toggleState,
          })}
        </StepContent>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
    {/* Tabs */}
    <div className="bg-white shadow-sm">
      <div className="flex justify-center space-x-12 py-4 border-b">
        <div
          className={`cursor-pointer px-4 py-2 ${toggleTab === 1 ? "text-orange-500 border-b-2 border-orange-500" : "text-gray-500"}`}
          onClick={() => setToggleTab(1)}
        >
          Single Order
        </div>
        <div
          className={`cursor-pointer px-4 py-2 ${toggleTab === 2 ? "text-orange-500 border-b-2 border-orange-500" : "text-gray-500"}`}
          onClick={() => setToggleTab(2)}
        >
          Quick Shipment
        </div>
      </div>
    </div>
  
    {/* Content */}
    {toggleTab === 1 && (
      <div className="flex flex-col min-h-screen bg-gray-100">
        {/* Progress Tabs */}
        <div className="bg-white shadow-sm">
          <div className="flex justify-center space-x-12 py-4">
            <div className={`flex flex-col items-center cursor-pointer ${toggleState >= 1 ? "text-gray-500" : "text-gray-500"}`}
              onClick={() => setToggleState(1)}
            >
              <FaUserCircle className="text-2xl mb-1" />
              <span
                className={`text-sm font-semibold ${toggleState >= 1 ? "text-orange-500" : "text-gray-500"} hidden md:block`}
              >
                Buyer Details
              </span>
              <p className="text-xs hidden md:block">Add Buyer's Details</p>
            </div>
  
            <div
              className={`flex flex-col items-center cursor-pointer ${toggleState >= 2 ? "text-gray-500" : "text-gray-500"}`}
              onClick={() => setToggleState(2)}
            >
              <IoListCircleSharp className="text-2xl mb-1" />
              <span
                className={`text-sm font-semibold ${toggleState >= 2 ? "text-orange-500" : "text-gray-500"} hidden md:block`}
              >
                Pickups Details
              </span>
              <p className="text-xs hidden md:block">Pickups basic information</p>
            </div>
  
            <div
              className={`flex flex-col items-center cursor-pointer ${toggleState >= 3 ? "text-gray-500" : "text-gray-500"}`}
              onClick={() => setToggleState(3)}
            >
              <MdOutlineShoppingCartCheckout className="text-2xl mb-1" />
              <span
                className={`text-sm font-semibold ${toggleState >= 3 ? "text-orange-500" : "text-gray-500"} hidden md:block`}
              >
                Order Details
              </span>
              <p className="text-xs hidden md:block">Order information</p>
            </div>
  
            <div
              className={`flex flex-col items-center cursor-pointer ${toggleState >= 4 ? "text-gray-500" : "text-gray-500"}`}
              onClick={() => setToggleState(4)}
            >
              <LuPackagePlus className="text-2xl mb-1" />
              <span
                className={`text-sm font-semibold ${toggleState >= 4 ? "text-orange-500" : "text-gray-500"} hidden md:block`}
              >
                Package Details
              </span>
              <p className="text-xs hidden md:block">Package information</p>
            </div>
  
          </div>
        </div>
  
        {/* Form Content */}
        <div className="flex-grow flex justify-center items-start p-6">
          <div className="w-full bg-white shadow-lg rounded-lg p-6">
            {renderCurrentComponent()}
          </div>
        </div>
      </div>
    )}
  
    {toggleTab === 2 && (
      <div className="flex-grow flex justify-center items-center p-6">
        {/* <p className="text-gray-500 text-xl">Coming Soon</p> */}
        <OrderForm />
      </div>
    )}
  </div>
  
  );
};

export default AddOrder;
