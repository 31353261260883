import React from 'react'

const Customers = () => {
  return (
    <div className='p-6'>
      Customers
      Customers
    </div>
  )
}

export default Customers
