import React, { useState,useEffect } from "react";
import CryptoJS from 'crypto-js';

const OrderDetailsForm = ({ handlePrevious, handleNext }) => {
  const [orderInfo, setOrderInfo] = useState({
    prodictOrderid: "",
    orderDate: "",
    // orderChannel: "",
    products: [
      { name: "", unitPrice: "", quantity: 1, category: "" },
    ],
    paymentMethod: "Prepaid",
    hsnCode: "",
    sku: "",
    taxRate: "",
    productDiscount: "0.00",
    shippingCharges: "0.00",
    giftWrap: "0.00",
    transactionFee: "0.00",
    discounts: "0",
  });

  const validateProductFields = () => {
    let isValid = true;
    let newErrors = {};
  
    orderInfo.products.forEach((product, index) => {
      if (!product.name || product.name.trim() === "") {
        newErrors[`productName-${index}`] = "Product name is required.";
        isValid = false;
      }
      if (!product.unitPrice || product.unitPrice <= 0) {
        newErrors[`unitPrice-${index}`] = "Unit price must be greater than 0.";
        isValid = false;
      }
      if (!product.quantity || product.quantity <= 0) {
        newErrors[`quantity-${index}`] = "Quantity must be greater than 0.";
        isValid = false;
      }
      if (!product.category || product.category.trim() === "") {
        newErrors[`category-${index}`] = "Category is required.";
        isValid = false;
      }
    });
  
    setErrors(newErrors);
    return isValid;
  };
  

  const [errors, setErrors] = useState({});

  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;
    if (index !== null) {
      const updatedProducts = [...orderInfo.products];
      updatedProducts[index][name] = value;
      setOrderInfo({ ...orderInfo, products: updatedProducts });
    } else {
      setOrderInfo((prevOrderInfo) => ({
        ...prevOrderInfo,
        [name]: value,
      }));
    }
  
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value.trim() !== "") {
        delete updatedErrors[index !== null ? `${name}-${index}` : name];
      }
      return updatedErrors;
    });
  };
  

  // const handleAddProduct = () => {
  //   setOrderInfo({
  //     ...orderInfo,
  //     products: [...orderInfo.products, { name: "", unitPrice: "", quantity: 1, category: "" }],
  //   });
  // };

  // const handleRemoveProduct = (index) => {
  //   const updatedProducts = orderInfo.products.filter((_, i) => i !== index);
  //   setOrderInfo({ ...orderInfo, products: updatedProducts });
  // };

  // const validateForm = () => {
  //   const newErrors = {};
  //   if (!orderInfo.paymentMethod) {
  //     newErrors.paymentMethod = "Please select a payment method.";
  //   }

  //   orderInfo.products.forEach((product, index) => {
  //     if (!product.name) newErrors[`productName-${index}`] = "Product name is required.";
  //     if (!product.unitPrice || isNaN(product.unitPrice)) newErrors[`unitPrice-${index}`] = "Unit price must be a number.";
  //     if (product.quantity < 1) newErrors[`quantity-${index}`] = "Quantity must be at least 1.";
  //     if (!product.category) newErrors[`category-${index}`] = "Product category is required.";
  //   });

  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  const handlesaveOrderInfo = (e) => {
    if (validateProductFields()) {
      // Proceed with form submission
      console.log("Form is valid:", orderInfo.products);
      try {
        const jsonData = JSON.stringify(orderInfo);
        const encodedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(jsonData));
        localStorage.setItem("orderinfo", encodedData);
        handleNext();
      } catch (error) {
        console.error("Error saving to localStorage:", error.message);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
    console.log("orderInfo------------->",orderInfo)
    // setLoading(true);
  };

    useEffect(() => {
      try {
        const saveOrderInfo = localStorage.getItem("orderinfo");
        if (saveOrderInfo) {
          console.log("Retrieved data from localStorage:", saveOrderInfo);
          const decodedData = CryptoJS.enc.Base64.parse(saveOrderInfo).toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decodedData);
          setOrderInfo(parsedData);
        }
      } catch (error) {
        console.error("Error loading from localStorage:", error.message);
      }
    }, []);
    
    // console.log("orderInfo",orderInfo);
  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-md" >
      {/* Order Details */}
      <h2 className="text-lg font-semibold mb-4">Order Details</h2>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium">Product Order ID</label>
          <input type="text" value={orderInfo.prodictOrderid}
          name="prodictOrderid"
          onChange={(e)=>handleInputChange(e)}
          className="px-4 py-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm"/>
        </div>
        <div>
          <label className="block text-sm font-medium">Order Date</label>
          <input
            type="date"
            name="orderDate"
            value={orderInfo.orderDate}
            onChange={(e)=>handleInputChange(e)}
            className="px-4 py-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>
       {/*  <div>
          <label className="block text-sm font-medium">Order Channel</label>
          <select name="orderChannel" value={orderInfo.orderChannel}
            onChange={handleInputChange}
            className="px-4 py-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          >
            <option value="CUSTOM">CUSTOM</option>
            <option value="ONLINE">ONLINE</option>
            <option value="STORE">STORE</option>
          </select>
        </div>*/}
      </div>
      {/* Product Details */}
      <h2 className="text-lg font-semibold mt-6 mb-4">Product Details</h2>
      {orderInfo.products.map((product, index) => (
        <div key={index} className="grid grid-cols-4 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium">Product Name</label>
            <input type="text" name="name" value={product.name} onChange={(e) => handleInputChange(e, index)}
              className={`px-4 py-2 mt-1 block w-full rounded-md shadow-sm ${errors[`productName-${index}`] ? "border-red-500" : ""}`}
            />
            {errors[`productName-${index}`] && <p className="text-red-500 text-sm">{errors[`productName-${index}`]}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium">Unit Price</label>
            <input type="number" name="unitPrice"
              value={product.unitPrice}
              onChange={(e) => handleInputChange(e, index)}
              className={`px-4 py-2 mt-1 block w-full rounded-md shadow-sm ${errors[`unitPrice-${index}`] ? "border-red-500" : ""}`}
            />
            {errors[`unitPrice-${index}`] && <p className="text-red-500 text-sm">{errors[`unitPrice-${index}`]}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium">Quantity</label>
            <input type="number" name="quantity"
              value={product.quantity}
              onChange={(e) => handleInputChange(e, index)}
              className={`px-4 py-2 mt-1 block w-full rounded-md  shadow-sm ${errors[`quantity-${index}`] ? "border-red-500" : ""}`}
            />
            {errors[`quantity-${index}`] && <p className="text-red-500 text-sm">{errors[`quantity-${index}`]}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium">Category</label>
            {/* <input type="text" name="category" value={product.category}
              onChange={(e) => handleInputChange(e, index)}
              className={`px-4 py-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm ${errors[`category-${index}`] ? "border-red-500" : ""}`}
            /> */}
            <select 
            name="category"
            value={product.category}
            onChange={(e) => handleInputChange(e, index)}
            className={`px-4 py-2 mt-1 block w-full rounded-md shadow-sm ${errors[`category-${index}`] ? "border-red-500" : ""}`}>
              <option value="Grocery">Grocery</option>
              <option value="F&B">F&B</option>
              <option value="Fashion">Fashion</option>
              <option value="BPC">BPC</option>
              <option value="Electronics">Electronics</option>
              <option value="Appliances">Appliances</option>
              <option value="Home&kitchen">Home & Kitchen</option>
              <option value="Health&Wellness">Health & Wellness</option>
              <option value="Pharma">Pharma</option>
            </select>
            {errors[`category-${index}`] && <p className="text-red-500 text-sm">{errors[`category-${index}`]}</p>}
          </div>
          <div className="flex items-end">
            {/* <button
              type="button"
              onClick={() => handleRemoveProduct(index)}
              className="text-red-500"
            >
              Remove
            </button> */}
          </div>
        </div>
      ))}
      {/* <button
        type="button"
        onClick={handleAddProduct}
        className="bg-blue-500 text-white px-4 py-2 rounded-md"
      >
        + Add Another Product
      </button> */}

<h1 className="text-xl font-bold mb-6">Billing Details (Optional) </h1>

{/* HSN Code, SKU, Tax Rate, Discount Section */}
<div className="mb-6">
  <h2 className="text-lg font-semibold mb-4">HSN Code, SKU, Tax Rate, and Discount</h2>
  <div className="grid grid-cols-4 gap-4">
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">HSN Code</label>
      <input
        type="text"
        name="hsnCode"
        value={orderInfo.hsnCode}
        onChange={(e)=>handleInputChange(e)}
        placeholder="Enter your product HSN code"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">SKU</label>
      <input
        type="text"
        name="sku"
        value={orderInfo.sku}
        onChange={(e)=>handleInputChange(e)}
        placeholder="Enter Product SKU"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">Tax Rate (%)</label>
      <input
        type="text"
        name="taxRate"
        value={orderInfo.taxRate}
        onChange={(e)=>handleInputChange(e)}
        placeholder="%"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">Product Discount</label>
      <input
        type="text"
        name="productDiscount"
        value={orderInfo.productDiscount}
        onChange={(e)=>handleInputChange(e)}
        placeholder="0.00"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>
  </div>
</div>

      {/* Payment Details */}
      <h2 className="text-lg font-semibold mt-6 mb-4">Payment Details</h2>
      <div>
        <label className="block text-sm font-medium">Payment Method</label>
        <div className="px-4 py-2 mt-1">
          <label className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="paymentMethod"
              value="Prepaid"
              onChange={(e)=>handleInputChange(e)}
              className="text-blue-500 "
              checked={orderInfo.paymentMethod === "Prepaid"}
            />
            <span className="ml-2">Prepaid</span>
          </label>
          {/* <label className="inline-flex items-center">
            <input
              type="radio"
              name="paymentMethod"
              value="Cash On Delivery"
              onChange={handleInputChange}
              className="text-blue-500"
            />
            <span className="ml-2">Cash On Delivery</span>
          </label> */}
        </div>
        {errors.paymentMethod && <p className="text-red-500 text-sm">{errors.paymentMethod}</p>}
      </div>


{/* Shipping Charges, Gift Wrap, Transaction Fee, Discounts Section */}
<div className="mb-6">
  <h2 className="text-lg font-semibold mb-4">Shipping Charges, Gift Wrap, Transaction Fee, and Discounts (optional) </h2>
  <div className="grid grid-cols-4 gap-4">
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">Shipping Charges</label>
      <input
        type="text"
        name="shippingCharges"
        value={orderInfo.shippingCharges}
        onChange={(e)=>handleInputChange(e)}
        placeholder="0.00"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">Gift Wrap</label>
      <input
        type="text"
        name="giftWrap"
        value={orderInfo.giftWrap}
        onChange={(e)=>handleInputChange(e)}
        placeholder="0.00"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">Transaction Fee</label>
      <input
        type="text"
        name="transactionFee"
        value={orderInfo.transactionFee}
        onChange={(e)=>handleInputChange(e)}
        placeholder="0.00"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">Discounts</label>
      <input
        type="text"
        name="discounts"
        value={orderInfo.discounts}
        onChange={(e)=>handleInputChange(e)}
        placeholder="0"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
      />
    </div>
  </div>
</div>




      <div className="flex justify-end gap-2 items-center mt-4">
        <button
          type="button"
          onClick={handlePrevious}
          className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-gray-200 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
          previous
        </button>
        <button onClick={(e)=>handlesaveOrderInfo(e)}
          type="button"
          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Save & Next
        </button>
      </div>


    </div>
  );
};

export default OrderDetailsForm;
